@import '../../partials/mixins';

.about-box-container {
	@include flexColumn();
	margin-top: 60px;
	width: 400px;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
	border-radius: 10px;

	.about-icon-container {
		@include flexRow(center, center);
		margin-top: -45px;

		img {
			width: 75px;
			height: 75px;
			background-color: white;
			border-radius: 100%;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
			padding: 10px;
		}
	}

	.about-data-container {
		@include text(initial, #737373, justify 12px);
		padding: 22px 42px;

		h5 {
			@include text(bold, black, center, 18px);
		}

		.read-more-button {
			background: none;
			border: none;
			padding: 0;
			font-family: arial, sans-serif;
			color: $primary-red;
			text-decoration: underline;

			&:not(:disabled):active {
				color: $primary-red;
				background: none;
				border: none;
			}

			&:not(:disabled):active:focus {
				box-shadow: unset;
			}
		}
	}

	@media (min-width: $extra-large-device) {
		width: 25%;
	}
}
