@import '../../partials/variables';
@import '../../partials/mixins';

.section-hero {
	@include flexRow(center, center);
	width: 100%;
	background-size: cover;
	border-radius: 0px 0px 10px 10px;
	padding: 40px;
	color: white;

	.hero-title {
		font-size: 36px;
		font-weight: bold;
	}

	@media (max-width: $small-device) {
		&.full-width {
			width: 100vw;
			margin-left: -20px;
		}
	}

	@media (min-width: $medium-device) {
		.hero-title {
			max-width: 477px;
			font-size: 64px;
		}
	}
}
