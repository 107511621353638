@import '../../partials/variables';
@import '../../partials/mixins';

#loader-container {
	@include flexRow(center, center);
	height: calc(100vh - 140px);
	width: 100%;

	.spinner-border {
		width: 5rem;
		height: 5rem;
		color: $primary-red;
		border: 0.4em solid currentColor;
		border-right-color: transparent;
	}
}
