@import '../../partials/variables';
@import '../../partials/mixins';

.vehicle-container {
	@include flexRow(center, space-evenly, wrap);
	width: 100%;
	height: 85px;
	max-width: 400px;
	min-height: 65px;
	margin-top: 20px;
	padding: 20px;
	border-radius: 5px;
	background-color: #f4f4f4;

	.vehicle-box-title {
		font-weight: 600;
		font-size: 13px;
		line-height: 16px;
	}

	.vehicle-details {
		@include flexColumn();
		font-size: 12px;
	}

	.vehicle-image-container {
		padding: 0 10px;
	}

	@media (min-width: $medium-device) and (max-width: $extra-large-device) {
		max-width: 400px;
	}

	@media (min-width: $large-device) {
		margin-top: 0;
	}
}
