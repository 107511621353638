.total-price {
	font-weight: bold;
	text-align: left;
	color: #737373;
	line-height: initial;

	.per-person-price {
		font-weight: 500;
		line-height: 22px;
		font-size: 18px;
		color: black;
		text-align: left;
	}
}
