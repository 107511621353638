@import './variables';

// GENERAL classes
.red {
	color: $primary-red;
}

.hidden {
	display: none !important;
}
