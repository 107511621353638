@import '../../partials/variables';
@import '../../partials/mixins';

#booking-bar-container {
	@include flexRow(center, center, wrap);
	width: 100%;
	height: 166px;
	left: 0px;
	position: fixed;
	bottom: 0px;
	background-color: rgb(255, 255, 255);
	box-shadow: 1px 0px 11px lightgrey;

	#pricing {
		width: 329px;
	}
	.btn-container {
		width: 954px;
		display: flex;
		justify-content: space-around;
	}
	.total-price {
		span {
			color: $primary-red;
			font-size: 48px;
		}
	}

	#dates-booking-buttons {
		justify-content: inherit;
		align-items: center;

		input {
			display: none;
		}

		> span {
			width: 250px;
			color: #dc3545;
			font-weight: bold;
			margin-top: 5px;
			cursor: pointer;
		}
	}

	.button-primary {
		width: 100%;
	}

	.alerts-container {
		width: 100%;

		.alert {
			margin: 0;
			margin-top: 5px;
		}
	}

	@media (min-width: $small-device) {
		.button-primary {
			width: 250px;
		}
	}

	@media (min-width: $medium-device) {
		justify-content: space-between;
		padding: 35px 30px;
	}
}
