@import '../../partials/variables';
@import '../../partials/mixins';

#google_translate_element {
	@media screen and (min-width: $small-device) {
		@include absolute(12px, 16px, auto, auto);
	}
}

.goog-te-banner-frame.skiptranslate {
	display: none !important;
}

.goog-te-combo,
.goog-te-banner *,
.goog-te-ftab *,
.goog-te-menu *,
.goog-te-menu2 *,
.goog-te-balloon * {
	font-family: arial;
	font-size: 10pt;
	background-repeat: no-repeat;
	text-indent: 20px;
	background-color: white;
	color: black !important;
}

.goog-logo-link {
	display: none !important;
}

.goog-te-gadget {
	color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
	margin: 2px 0 !important;
}
