@import '../../partials/variables';
@import '../../partials/mixins';

#auth-container {
	@include flexColumn(normal, center);
	padding: 0 30px;
	margin: 30px auto;
	max-width: 550px;

	.auth-provider-button {
		@include flexRow(center, center);
		border: none;
		padding: 15px;
		margin-bottom: 10px;
		color: black;
		width: 100%;
		background: linear-gradient(-8.52deg, #ffffff 0%, #eeeeee 100%);
		box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.07);
		border-radius: 5px;

		img {
			margin-right: 10px;
			height: 18px;
		}
	}

	.auth-divider {
		@include flexRow(center, space-between);
		margin: 18px 0;
	}

	.auth-divider-line {
		width: 42%;
	}

	.auth-divider-o {
		font-family: 'Open Sans';
		font-weight: normal;
	}

	.auth-fields {
		margin: 4px 0 30px 0;

		.input-group:not(:first-child) {
			margin-top: 15px;
		}
	}

	.auth-actions {
		button {
			margin: 5px 0;
		}

		.other-actions {
			margin-top: 10px;

			u {
				cursor: pointer;
			}
		}
	}

	@media (min-width: $small-device) {
		padding: 0;
	}
}
