@import '../../partials/mixins';

#trips-container {
	margin-top: -5px;
}

.trip-list-title {
	font-weight: bold;
	font-size: 24px;
	text-align: left;
	margin-bottom: 35px;
}
