@import './mixins';

.App {
	.container,
	.container-fluid {
		min-height: calc(100vh - 56px);
		margin-top: 56px;
		padding: 30px 20px 15px 20px;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.page-title {
		font-weight: 700;
		margin-bottom: 15px;
		font-size: 22px;

		&.full-width {
			text-align: left;
			margin: 30px;
		}
	}

	.step-actions-container {
		@include flexRow(normal, space-evenly, wrap);
		width: 350px;
		margin: 0 auto;

		button.button-primary {
			width: 140px;
			height: 38px;
			padding: 0;
			border-radius: 20px;
			margin-bottom: 5px;
			// font-size: 18px;

			&:active:focus {
				box-shadow: none;
			}
		}

		@media (max-width: $extra-small-device) {
			width: 100%;

			button.button-primary {
				width: 100%;
			}
		}
	}

	@media (min-width: $small-device) {
		.page-title.full-width {
			max-width: 550px;
			margin: 30px auto;
		}
	}
}
