.custom-switch-container {
	width: 45px;
	margin-top: -33px;
	.react-switch-checkbox {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	.react-switch-label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 45px;
		height: 20px;

		border: 0.7px solid #000000;

		border-radius: 100px;
		position: relative;
		transition: background-color 0.4s;
	}

	.react-switch-label .react-switch-button {
		content: '';
		position: absolute;
		width: 25px;
		height: 20px;
		border-radius: 45px;
		transition: 0.4s;
		background: #ffffff;
		border: 0.7px solid #000000;
	}

	.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
		left: calc(100%);
		transform: translateX(-50%);
	}
}
