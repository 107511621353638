@import './variables';

.progress-bar.bg-danger {
	background-color: $primary-red !important;
}

// Datepicker
.App .heJwJj,
.gKyapV {
	background-color: $primary-red;
}

.App .dsKVpZ,
.App .dyHccB:hover {
	color: $primary-red;
}

.App .custom-datepicker {
	background: $primary-red;
	border-radius: 50px;
	border: none;
	// min-width: 250px;
	height: 50px;
	cursor: pointer;
	color: white;
	font-weight: bold;
	outline: none;
	padding: 0 20px;
	text-align: center;

	&::placeholder,
	&::-moz-placeholder {
		color: white;
		text-align: center;
		opacity: 1;
	}

	&.white-button {
		background-color: white;
		color: $primary-red;
		text-align: center;
		border: solid;
		width: 100%;
		height: 40px;
		margin-top: 5px;

		&::placeholder {
			color: $primary-red;
		}
	}
}
