@import '../../partials/variables';
@import '../../partials/mixins';

#new-trip-container {
	#step-3 {
		.options-container {
			@media (max-width: $extra-small-device) {
				min-height: 470px;
			}

			@media (min-width: $extra-small-device) {
				min-height: 420px;
			}

			@media (min-width: $large-device) {
				min-height: 430px;
				align-items: center;
			}
		}
	}
}
