@import '../../partials/mixins';

#result-list-container {
	.result-grid {
		@include flexRow(normal, space-around, wrap);
		position: relative;
		margin-bottom: 30px;

		h4 {
			width: 100%;
			font-size: 1em;
		}
	}
}
