@import '../../partials/variables';
@import '../../partials/mixins';

#chat-container {
	.chat-navbar {
		@include flexRow(center, center);
		position: fixed;
		top: 55px;
		left: 0;
		width: 100%;
		max-width: $medium-device;
		height: 70px;
		background-color: $primary-gray;
		color: black;

		.chat-names {
			font-size: 18px;
			font-weight: bold;
		}
	}

	.messages-container {
		margin: 50px 0 80px 0;
	}

	.message {
		@include flexRow(flex-end, flex-end);
		margin-bottom: 10px;

		.message-text {
			width: 100%;
			padding: 20px 30px;
			text-align: left;
			border-radius: 8px;
		}

		&.sent {
			.message-text {
				margin-right: 15px;
				color: white;
				background-color: #949494;
				border-bottom-right-radius: 0;
			}
		}

		&.received {
			flex-direction: row-reverse;

			.message-text {
				margin-left: 15px;
				color: black;
				background-color: $primary-gray;
				border-bottom-left-radius: 0;
			}
		}
	}

	.input-fixer {
		position: fixed;
		bottom: 10px;
		width: 70%;
		max-width: 960px;
		margin: 0 auto;
	}

	.input-container {
		@include flexRow(center, center, wrap);
		position: relative;
	}

	.chat-input {
		height: 60px;
		width: 100%;
		padding: 20px;
		border: none;
		background-color: #f7f7f7;
		border-radius: 10px;
		color: $input-gray;
	}

	.button-chat {
		@include absolute(auto, 20px, auto, auto);
		width: 80px;
		height: 35px;
		color: $primary-red;
		border: none;
		border-radius: 7px;
		background-color: #b0b0b0b0;
		font-weight: 500;
	}
}

@media (min-width: $medium-device) {
	#chat-container .chat-navbar {
		left: initial;
	}
}
