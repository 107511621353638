@import '../../partials/variables';
@import '../../partials/mixins';

.group-size-inputs-container {
	@include flexRow(center, center, wrap);
	width: 100%;
	max-width: 300px;
	margin: 10px 0;
	font-style: normal;
	font-size: 20px;

	p {
		font-size: 20px;
		font-weight: 600;
		width: 85%;
		text-align: left;
		margin: 0;
	}

	.number-input {
		@include flexRow(center);
		margin-bottom: 5px;

		span {
			@include flexRow(center, center);
			color: white;
			width: 52px;
			height: 52px;
			background: $primary-gradient;
			cursor: pointer;
		}

		.btn-increase {
			border-radius: 0px 15px 15px 0px;

			&.disabled {
				opacity: 0.5;

				.number-input span {
					cursor: default;
				}
			}
		}

		.btn-decrease {
			border-radius: 15px 0px 0px 15px;
		}

		.btn-increase,
		.btn-decrease {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
		}

		.num-travelers {
			width: 82px;
			height: 52px;
			font-size: 12px;
			text-align: center;
			color: $input-gray;
			border: none;
		}

		input:disabled {
			background: white;
			box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}
}
