@import '../../partials/variables';
@import '../../partials/mixins';

.user-info-box {
	@include flexRow(center);
	width: 50%;
	padding: 0 6px;
	color: $input-gray;
	line-height: 20px;

	&:not(:last-child) {
		border-right: 0.5px solid $divider-gray;
	}

	span {
		@include flexRow(center);
		font-size: 8.5px;
		font-weight: normal;
		line-height: 11px;

		img {
			margin-right: 5px;
		}
	}
}
