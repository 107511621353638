@import '../../partials/variables';

#activity-tile-container {
	&.utility-layout {
		display: flex;
		justify-content: space-between;
		background-color: white;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
		min-height: 95px;
		padding: 0;
		padding-right: 15px;

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		.activity-img {
			width: 40%;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		.activity-details-preview {
			padding: 10px 0 0 32px;
			text-align: left;
		}
	}

	display: flex;
	justify-content: space-between;
	background: #f2efef;
	padding: 15px 28px;
	min-height: 70px;
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 6px;
	}

	.activity-details-preview {
		width: 100%;
		padding-right: 10px;
	}

	.activity-details-icon {
		width: 20px;
		justify-content: flex-end;
		display: flex;
		align-items: center;
		color: #b0b0b0;
		font-size: xx-large;
		cursor: pointer;
	}
	.activity-details-icon:hover {
		color: $primary-red;
	}
	.activity-name {
		font-weight: 600;
		font-size: 15px;
		line-height: 18px;
		padding-bottom: 5px;
		color: #000000;
	}
	.activity-time {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: #000000;
	}

	@media (min-width: $small-device) {
		border-radius: 10px;
	}
}
