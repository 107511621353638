// color palette
$primary-red: #ea0029;
$primary-gradient: linear-gradient(90.59deg, #ee315f 9.53%, #eb2237 70.25%),
	$primary-red;
$primary-gray: #c4c4c4;
$secondary-gray: #f1f1f1;
$inactive-gray: #6c757d;
$input-gray: #929292;
$divider-gray: #dddddd;
$star-yellow: #ffe600;

// media sizes
$extra-small-device: 320px;
$small-device: 576px;
$medium-device: 768px;
$large-device: 992px;
$extra-large-device: 1200px;
