@import './variables';
@import './mixins';

.section-container {
	@include flexRow(normal, space-between, wrap);
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
	border-radius: 0px 0px 20px 20px;
	padding: 25px;

	.section-side {
		width: 100%;
		padding-bottom: 20px;
	}

	&.two-column-section {
		.left-side {
			border-right: none;
			border-bottom: 2px solid $primary-gray;
		}

		.right-side {
			margin-top: 13px;
		}
	}
}

@media (min-width: $medium-device) {
	.section-container {
		padding: 70px 90px;
	}
}

@media (min-width: $large-device) {
	.section-container {
		flex-direction: row;

		.section-side {
			padding-bottom: 0;
		}

		&.two-column-section {
			.section-side {
				width: 50%;
				padding: 0;
			}

			.left-side {
				border-bottom: none;
				border-right: 2px solid $primary-gray;
				padding-right: 6%;
			}

			.right-side {
				margin-top: 0;
				padding-left: 6%;
			}
		}
	}
}
