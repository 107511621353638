@import '../../partials/variables';
@import '../../partials/mixins';

.user-profile-container {
	padding-bottom: 50px;
	margin-bottom: 250px;

	.user-header-row {
		@include flexRow(normal, center, wrap);
		margin-top: 370px;
	}

	.user-core-column {
		@include flexRow(center, center);
		width: 100%;

		.user-core-data {
			@include flexColumn(flex-start);
			margin: 15px;
		}

		.user-name {
			@include flexRow(normal, flex-start);
			font-weight: 900;
			font-size: 18px;
			margin-bottom: 10px;
		}

		.info-container {
			@include flexColumn(flex-start, space-evenly);
			margin-top: 5px;
			padding-left: 10px;
			border-left: solid 0.5px lightgray;
		}
	}

	.top-interests-column {
		@include flexColumn(center, flex-start);
		margin: 15px 0;
		width: 100%;
	}

	.other-info-column {
		@include flexColumn(center, center);
		width: 100%;
		margin-bottom: 15px;
	}

	.user-about-container {
		@include flexRow(normal, space-around, wrap);
		margin-top: 50px;
		border-top: 1px solid $divider-gray;
	}

	@media (max-width: $extra-small-device) {
		.user-core-column {
			flex-direction: column;
		}
	}

	@media (max-width: $small-device) {
		.user-header-row {
			margin-top: 415px;
		}

		.user-core-column {
			.user-name {
				justify-content: center;
			}
		}
	}

	@media (min-width: $medium-device) {
		.user-header-row {
			margin-top: 380px;
		}

		.user-core-column {
			width: 50%;
		}

		.top-interests-column {
			width: 50%;
		}
	}

	@media (min-width: $large-device) {
		margin-bottom: 150px;

		.user-core-column {
			width: 34.3% !important;

			.user-name {
				margin-left: 10px;
			}

			.info-container {
				border-left: none;
			}

			.user-photo .user-image {
				width: 154px;
				height: 154px;
			}
		}

		.top-interests-column {
			width: 33.3% !important;
			padding-right: 10px;
		}

		.other-info-column {
			width: 32.3% !important;
			margin-top: 35px;
			padding-left: 10px;
		}
	}
}

.tooltip {
	margin-bottom: 50px !important;
}

// TODO: handle translate widget display for user profiles
// #google_translate_element {
// @include absolute(400, 10vw, auto, auto)
// 	z-index: 2;

// 	@media (max-width: $small-device) {
// 		width: 100%;
// 		left: 0;
// 	}

// 	@media (min-width: $medium-device) {
// 		top: 380px;
// 	}
// }
