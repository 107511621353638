@import './variables';
@import './mixins';

input.form-input {
	border: none;
	border-bottom: solid 1px $primary-gray;
	width: 100%;
	border-radius: 0;
	font-size: 12px;
}

input.form-input:focus {
	border: none;
	border-bottom: solid 1px #050505;
	box-shadow: none;
	color: #050505;
}

input.form-input::placeholder {
	color: #cccccc;
}

.input-group-prepend {
	@include flexRow(center);
	border: none;
	color: #cccccc;
	padding-left: 5px;
}

input.bottom-line {
	outline: 0;
	border-width: 0 0 2px;
	border-color: $primary-gray;
	width: 409px;
}

input.bottom-line:focus {
	border-color: $secondary-gray;
}

input.bottom-line::placeholder {
	font-size: 14px;
}
input.email-log-in {
	width: 361px;
	height: 53px;
	background: $secondary-gray;
	border-radius: 10px 0px 0px 10px;
}

input.overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
