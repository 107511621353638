@import '../../partials/variables';
@import '../../partials/mixins';

#new-trip-container {
	#step-4 {
		.dates-container {
			@include flexRow(normal, center, wrap);
			padding: 11px;

			button.no-dates-button {
				@include text($textAlign: justify);
				@include button(
					$primary-gray,
					$input-gray,
					none,
					20px,
					15px 18px,
					265px
				);

				&.active {
					@include color($primary-red, white, none);
					box-shadow: none;
				}

				&:focus {
					box-shadow: none;
				}
			}
		}

		input {
			@include color(#c4c4c4, #777777, none);
			@include text(500);
			width: 265px;
			height: 50px;
			padding: 15px 18px;
			border-radius: 10px;

			&.filled {
				@include color($primary-red, white, none);
			}
		}

		@media (max-width: $extra-small-device) {
			input {
				width: 100%;
			}

			.dates-container {
				button.no-dates-button {
					width: 100%;
				}
			}
		}

		@media (min-width: $large-device) {
			.dates-container {
				padding: 0;
			}
		}
	}
}
