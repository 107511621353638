@import './variables';

svg {
	&.add-activity {
		width: 20px !important;
		height: 20px;
		padding: 4px;
		margin: 0 6px;
		color: $primary-red;
		border: 1px solid red;
		border-radius: 100%;
		background-color: rgba(234, 0, 41, 0.3);
		cursor: pointer;
	}

	&.clickable {
		cursor: pointer;
	}
}
