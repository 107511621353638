#new-trip-container {
	#step-2 {
		.city-container {
			width: 100%;
			max-width: 365px;
			height: calc(300px - 80px);
		}

		.city-box {
			box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
			border-radius: 15px;
			background-position: center;
			height: calc(50% - 10px);

			// &:hover {
			// 	box-shadow: 0px 0px 7px #ea1c2d;
			// }
		}
	}
}
