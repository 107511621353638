@import '../../../partials/variables';

#activity-slider-container {
	width: 100%;
	height: 100vh;
	display: flex;
}
.img-container {
	width: 100%;
	background-size: cover;

	display: flex;
	justify-content: space-between;
	background-position: center;
	padding: 30px;
	.controls {
		position: relative;
		width: 100%;
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	.slider-left {
		font-size: 50px;
		color: white;
		font-size: 20px;
		cursor: pointer;
	}
	.slider-right {
		font-size: 50px;
		color: white;
		font-size: 20px;
		cursor: pointer;
	}
	.circle-controler {
		width: 100%;
		height: 20px;
		display: flex;
		position: absolute;
		bottom: 5px;
		justify-content: center;
		align-items: center;

		.circle-index {
			width: 15px;
			height: 15px;
			background: rgba(255, 255, 255, 0.49);
			border-radius: 50%;
			margin-right: 10px;
			cursor: pointer;
		}
		.circle-index-selected {
			background: #ffffff;
		}
	}

	@media (max-width: $small-device) {
		padding: 15px;

		.circle-controler .circle-index {
			width: 7px;
			height: 7px;
		}
	}
}

.close-menu {
	position: absolute;
	right: 10px;
	font-size: 30px;
	top: 0px;
	cursor: pointer;
	z-index: 99;
}
