@import '../../partials/variables';
@import '../../partials/mixins';

#itinerary-builder-container {
	margin-bottom: 20px;

	.heading {
		text-align: left;
		font-weight: bold;
		font-size: 30px;
		color: $primary-red;
		margin-bottom: 20px;

		svg {
			font-size: 16px;
			color: $input-gray;
			cursor: pointer;
		}
	}

	.edit-icon.active {
		color: $primary-red;
	}

	.download-invoice,
	.edit-icon {
		margin-left: 25px;
	}

	.sub-heading {
		font-style: normal;
		font-size: 12px;
		line-height: 15px;
		color: #000000;
	}

	.save-itinerary {
		display: flex;
		margin-top: 20px;

		.sub-heading {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #000000;
		}

		img {
			width: 11px;
			height: 16px;
			margin-right: 10px;
			margin-top: 5px;
			cursor: pointer;
		}
	}
}
