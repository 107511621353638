@import '../../partials/mixins';

.detail-item {
	@include flexRow(center, space-between);
	padding: 20px 0;

	&.bordered {
		border-bottom: 1px solid #f0f0f0;
	}

	&:last-child {
		border-bottom: none;
	}

	label {
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 0;
	}

	span {
		font-size: 12px;
	}

	input {
		width: 150px;

		&:focus {
			box-shadow: none;
			border-color: $primary-red;
		}
	}

	&.price {
		span {
			font-size: 18px;
			font-weight: 700;
		}

		.per-person-container {
			font-size: 10px;
			font-weight: normal;
		}
	}
}
