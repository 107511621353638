@import '../../partials/variables';
@import '../../partials/mixins';

#profile-edit-container {
	margin-top: 35px;

	form {
		flex-direction: column;
	}

	.profile-photo {
		position: relative;
		cursor: pointer;

		.upload-photo-icon {
			@include flexRow(center, center);
			@include absolute(auto, 0, 0, auto);
			height: 43px;
			width: 43px;
			background-color: $primary-red;
			border-radius: 100%;
			color: white;
			cursor: pointer;
		}
	}

	.edit-fields {
		margin-top: 30px;
	}
}
