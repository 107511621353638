@import '../../partials/variables';
@import '../../partials/mixins';

#auth-container {
	.text-icon {
		position: absolute;
		z-index: 5;
		height: 26px;
	}
	@include flexColumn(normal, center);
	padding: 0 30px;
	margin: 25px auto 35px auto;
	width: 453px;
	.auth-provider-button {
		@include flexRow(center, center);
		border: none;
		padding: 15px;
		margin-bottom: 10px;
		color: black;
		width: 100%;
		background: linear-gradient(-8.52deg, #ffffff 0%, #eeeeee 100%);
		box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.07);
		border-radius: 5px;
	}
	.auth-title {
		font-size: 24px;
		line-height: 29px;
		font-weight: bold;
		text-align: center;
		color: $primary-red;
		margin-bottom: 25px;
		margin-top: 10px;
	}
	.icon-btn {
		width: 38px;
		height: 26px;
	}
	.social-media-sing-up {
		@include flexColumn(center, center);
		button {
			margin-bottom: 10px;
		}
	}

	.auth-divider {
		@include flexRow(center, space-between);
		margin: 18px 0;
		width: 100%;
	}

	.auth-divider-line {
		width: 42%;
	}

	.auth-divider-o {
		font-family: 'Open Sans';
		font-weight: normal;
	}

	.auth-fields {
		margin: 4px 0 30px 0;
		input[type='text'] {
			padding-left: 50px;
		}
		input[type='password'] {
			padding-left: 50px;
		}
		.input-group:not(:first-child) {
			margin-top: 15px;
		}
	}

	.auth-actions-container {
		@include flexColumn(center, center);
	}

	.other-actions-link {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #8c8686;
		u {
			cursor: pointer;
			color: #000000;
			margin-left: 3px;
		}
	}
	@media (min-width: $small-device) {
		padding: 0;
	}
}

.nav {
	&.nav-tabs {
		height: 60px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
		padding: 0 50px;
	}

	.nav-item {
		cursor: pointer;
		font-weight: bold;

		a {
			font-size: 18px;
			line-height: 22px;
		}

		&:not(:first-child) {
			margin-left: 15px;
		}
		&:hover {
			color: $primary-red;
		}
		.nav-link {
			border-radius: 10px 10px 0px 0px;
			margin-top: 18px;
			height: 41px;
			color: $primary-gray;
		}
		.active-tab {
			color: white;
			background: linear-gradient(
				285.94deg,
				#ea0129 30.64%,
				#ee315f 81.08%
			);
			border-radius: 10px 10px 0px 0px;
			&:hover {
				color: white;
			}
		}
	}
}
