@charset "UTF-8";
#activity-description-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100vh;
  overflow: auto;
  font-size: 18px; }
  #activity-description-container .activity-slider-container {
    width: 50%;
    height: 600px; }
  #activity-description-container .activity-description-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 50%;
    height: 100%;
    padding: 25px 50px;
    overflow-y: auto;
    overflow-x: hidden; }
    #activity-description-container .activity-description-content .content {
      width: 545px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
  #activity-description-container .activity-title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 37px;
    width: 100%;
    color: #000000;
    text-align: left;
    margin-bottom: 10px; }
  #activity-description-container .activity-time {
    display: flex;
    text-align: left;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: flex-start;
    margin-bottom: 30px; }
    #activity-description-container .activity-time .kid-frinedly-container {
      display: flex; }
      #activity-description-container .activity-time .kid-frinedly-container div {
        display: flex; }
    #activity-description-container .activity-time .kid-frinedly-container:before {
      content: '• ';
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px; }
    #activity-description-container .activity-time p {
      margin-left: 5px;
      margin-right: 5px; }
    #activity-description-container .activity-time img {
      width: 19px;
      height: 18px; }
  #activity-description-container .activity-author-desktop {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    margin-bottom: 25px; }
  #activity-description-container .activity-author-mobile {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 10px;
    display: none; }
  #activity-description-container .activity-description-title {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 20px; }
  #activity-description-container .activity-description {
    text-align: justify;
    width: 450px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    text-align: left;
    margin-bottom: 35px; }
    #activity-description-container .activity-description ul {
      padding: 10px 20px; }
      #activity-description-container .activity-description ul:last-child {
        padding-bottom: 0; }
    #activity-description-container .activity-description .description-see-more {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      justify-content: flex-end;
      color: #ea0029;
      cursor: pointer; }
      #activity-description-container .activity-description .description-see-more:hover {
        font-weight: bold; }
    #activity-description-container .activity-description .arrow-see-more {
      width: 9px;
      height: 7px;
      margin-top: 4px;
      margin-left: 4px; }
  @media (min-width: 576px) {
    #activity-description-container .activity-time {
      font-size: 13px; } }
  @media (max-width: 992px) {
    #activity-description-container .activity-slider-container {
      width: 100%;
      height: 275px; }
      #activity-description-container .activity-slider-container .img-container {
        width: 100%;
        height: 275px; }
      #activity-description-container .activity-slider-container .slider-left {
        display: none; }
      #activity-description-container .activity-slider-container .slider-right {
        display: none; }
      #activity-description-container .activity-slider-container .circle-controler {
        width: 100%;
        bottom: 0px; }
    #activity-description-container .activity-description-content {
      width: 100%;
      font-size: 12px;
      padding: 40px 35px;
      height: auto; }
      #activity-description-container .activity-description-content .content {
        width: 100%; }
    #activity-description-container .activity-author-desktop {
      display: none; }
    #activity-description-container .activity-author-mobile {
      display: inline; } }
  #activity-description-container .add-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    width: 50%;
    margin-top: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    background: #ffffff;
    height: 52px;
    padding: 10px;
    right: 0;
    bottom: 0; }
    #activity-description-container .add-btn span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: normal;
      flex-wrap: nowrap;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ea0029;
      text-align: right; }
    #activity-description-container .add-btn .suggestion-add-icon {
      border: 1px solid #ea0029;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: rgba(234, 0, 41, 0.3);
      cursor: pointer;
      color: #ea0029;
      margin-left: 10px;
      font-size: 15px; }
  @media (max-width: 992px) {
    #activity-description-container .add-btn {
      width: 100%; }
    #activity-description-container .activity-description-title {
      font-size: 20px;
      margin-bottom: 5px; }
    #activity-description-container .activity-title {
      font-size: 30px; } }
