@import '../../partials/variables';
@import '../../partials/mixins';

.user-edit-field-input {
	text-align: left;
	margin: 20px 0;

	&:first-child {
		margin-top: 10px;
	}

	&.photo-input {
		text-align: center;
	}

	label {
		font-weight: 600;
		font-size: 12px;
	}

	select,
	input {
		font-weight: 500;
		font-size: 12px;
		width: 100%;
		height: 42px;
		padding-left: 12px;
		padding-right: 12px;

		&:focus {
			box-shadow: none;
		}
	}

	input {
		padding-left: 12px;
		padding-right: 12px;
		background-color: #f7f7f7;
		border: none;
		border-radius: 5px;

		&:focus {
			background-color: #f7f7f7;
		}
	}

	textarea {
		width: 100%;
		height: 100px;
		font-size: 12px;
		font-weight: 500;
		color: #050505;
		background-color: #f7f7f7;
		border: none;
		border-radius: 5px;
		padding: 13px;
	}

	.bday-container {
		@include flexRow(normal, flex-start);
		width: 70%;

		input {
			height: 42px;
			width: 84px;
			text-align: center;
			margin-right: 5%;
		}
	}
}
