@import '../../partials/variables';

#share-buttons {
	margin-top: 20px;
	color: $primary-red;
	// margin-bottom: 210px;

	.share-text {
		margin-bottom: 10px;
		font-weight: bold;
	}

	button {
		cursor: pointer;
	}

	button:not(:last-child) {
		margin-right: 10px;
	}
}
