@import '../../partials/variables';
@import '../../partials/mixins';

.interest-bar {
	@include flexRow(center);
	width: 100%;
	margin-top: 10px;

	svg {
		width: 25px !important;
		color: $primary-red;
		margin-right: 7px;
	}

	.progress {
		width: 100%;
		height: 12px;
		border-radius: 10px;
		background-color: black;
	}
}
