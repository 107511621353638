@import '../../partials/variables';

#not-found {
	.header {
		font-size: 50px;
	}

	.error-code {
		line-height: 1;
		font-weight: bold;
	}

	.error-message {
		font-size: 30px;
	}

	@media (max-width: $extra-small-device) {
		.error-message {
			font-size: 20px;
		}

		.error-code {
			font-size: 100px;
		}

		.header {
			font-size: 30px;
		}
	}

	@media (min-width: $extra-small-device) {
		.error-code {
			font-size: 150px;
		}
	}

	@media (min-width: $small-device) {
		.error-code {
			font-size: 250px;
		}
	}
}
