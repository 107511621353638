@import './variables';

@mixin flexRow(
	$alignItems: normal,
	$justifyContent: normal,
	$flexWrap: nowrap
) {
	display: flex;
	flex-direction: row;
	align-items: $alignItems;
	justify-content: $justifyContent;
	flex-wrap: $flexWrap;
}

@mixin flexColumn(
	$alignItems: normal,
	$justifyContent: normal,
	$flexWrap: nowrap
) {
	display: flex;
	flex-direction: column;
	align-items: $alignItems;
	justify-content: $justifyContent;
	flex-wrap: $flexWrap;
}

@mixin button(
	$backgroundColor: $primary-red,
	$color: white,
	$border: none,
	$borderRadius: 50px,
	$padding: 15px,
	$width: 100%
) {
	background-color: $backgroundColor;
	color: $color;
	border: $border;
	border-radius: $borderRadius;
	padding: $padding;
	width: $width;
}

@mixin color($backgroundColor, $color, $border) {
	background-color: $backgroundColor;
	color: $color;
	border: $border;
}

@mixin text(
	$fontWeight: 500,
	$color: inherit,
	$textAlign: left,
	$fontSize: 1rem,
	$textDecoration: none
) {
	font-weight: $fontWeight;
	color: $color;
	text-align: $textAlign;
	font-size: $fontSize;
	text-decoration: $textDecoration;
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

// TODOS:
@mixin background($backgroundSize) {
	background-size: $backgroundSize;
}

@mixin border($border, $borderSize, $borderColor, $borderRadius) {
	border: $borderSize $border $borderColor;
	border-radius: $borderRadius;
}
