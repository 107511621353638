@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans&display=swap');

html {
	font-size: 14px;
}

body {
	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: black !important;
	min-height: calc(100% - 56px) !important;
	top: 0;
}

*:focus {
	outline: none !important;
}

.App {
	text-align: center;

	select {
		background-color: #f7f7f7;
		border: none;

		&:focus {
			background-color: #f7f7f7;
			border: none;
		}
	}

	a {
		color: initial;

		&:hover {
			color: initial;
			text-decoration: none;
		}
	}
}
