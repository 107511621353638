@import '../../partials/variables';
@import '../../partials/mixins';

.ambassador-result-card {
	@include flexColumn(center, flex-end);
	position: relative;
	margin-top: 60px;
	padding: 20px;
	width: 100%;
	height: calc(95vh - 80px);
	max-height: 550px;
	box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
	border-radius: 25px;

	button {
		font-size: 11px;
		min-width: 100px;
		line-height: 11px;
	}

	.card-photo-container {
		@include absolute(0, auto, auto, auto);
		width: 100%;
		height: 48%;
		cursor: pointer;

		.photo-profile {
			width: 100%;
			height: 100%;
			background-color: white;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
		}
	}

	.card-info-body {
		@include flexRow(normal, space-around, wrap);
		width: 100%;
		height: calc(50% - 90px);
	}

	.card-info {
		@include flexRow(center, space-between, wrap);
		width: 100%;
		font-size: 10px;

		.card-headline {
			@include flexRow(normal, space-between);
			width: 100%;

			> span {
				font-size: 18px;
				font-weight: bold;
			}
		}

		.card-data {
			@include flexRow(normal, space-between);
			width: 100%;
			border-left: 0.5px solid $divider-gray;
			border-right: 0.5px solid $divider-gray;
			margin-bottom: 10px;
		}
	}

	.card-info-footer {
		@include flexRow(normal, space-between, wrap);
		width: 100%;
		border-top: 0.5px solid $divider-gray;
		padding-top: 15px;

		.pricing {
			@include flexRow(center, normal, wrap);
			font-size: 9px;
			margin-bottom: 10px;
		}
	}

	@media screen and (min-width: $small-device) {
		width: 275px;
	}
}
