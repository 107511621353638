@import '../../partials/variables';
@import '../../partials/mixins';

.carousel.carousel-slider {
	@include absolute(0, 0, auto, auto);
	margin-top: -30px;
	height: 400px;
	cursor: pointer;
	background: black;

	.carousel-image-container {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		height: 400px;
	}

	@media (max-width: $small-device) {
		.slide {
			margin: 1px !important;
			opacity: 0.5;

			&.selected {
				opacity: 1;
			}

			.carousel-image-container {
				background-size: cover;
			}
		}
	}

	@media (min-width: $small-device) {
		margin-top: -30px;
	}
}
