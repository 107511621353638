@import '../../partials/variables';
@import '../../partials/mixins';

.day-activities-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
	border-top: 1px solid $divider-gray;

	.day-activities-title {
		text-align: center;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		margin-top: 50px;
		color: #ea1c2d;
		width: 100%;

		div {
			margin-top: 10px;
			color: #929292;
		}

		@media (max-width: 500px) {
			font-size: 18px;

			div {
				font-size: 1em;
			}
		}
	}

	.timeline-container {
		max-width: 1400px;
		margin: 30px auto;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		padding: 30px;

		.timeline {
			list-style: none;
			padding: 0;
			position: relative;
			display: flex;
			flex-direction: column;

			@media (min-width: $extra-large-device) {
				flex-direction: row;
			}

			&:before {
				@include absolute(0, auto, auto, 0);
				content: '';
				margin-left: 100px;
				width: 2px;
				height: 100%;
				background-image: linear-gradient(
					to bottom,
					black 8%,
					black 8%,
					black 100%
				);
				transform: translateX(calc(50% + 30px / 2));

				@media (max-width: $extra-large-device) {
					margin-top: 35px;
					margin-left: 0px;
					height: 81%;
				}
				@media (max-width: 500px) {
					height: 79%;
				}
				@media (min-width: $extra-large-device) {
					width: 80%;
					height: 2px;
					border-radius: 4px;
					background-image: linear-gradient(
						to right,
						black 8%,
						black 8%,
						black 100%
					);
					transform: translateY(calc(50% + 30px / 2));
				}
			}

			&__step {
				display: flex;
				align-items: center;
				flex: 1;
				text-align: center;
				position: relative;

				flex-direction: row;
				padding: 30px 0;
				margin-top: 5px;

				@media (min-width: $extra-large-device) {
					flex-direction: column;
					padding: 0 30px;
				}
				@media (max-width: $extra-large-device) {
					align-items: baseline;
				}
				@media (max-width: 500px) {
					padding: 10px 0;
				}

				&:before {
					content: '';
					min-width: 30px;
					height: 30px;
					border-radius: 40px;
					border: solid 6px #dc3545;
					background-color: #dc3545;

					margin: 0 30px 0 0;
					@media (min-width: $extra-large-device) {
						margin: 0 0 30px 0;
					}
					@media (max-width: 500px) {
						min-width: 20px;
						height: 20px;
						margin-left: 7px;
					}
				}
			}

			&__title {
				display: inline-block;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
				color: #737373;
				line-height: 15px;
				width: fit-content;
				@media (max-width: 1199px) {
					text-align: left;
				}
				@media (max-width: 500px) {
					text-align: initial;
				}
			}
		}

		.timeline-hour {
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			color: #ea1c2d;
			font-weight: bold;
			@media (max-width: $extra-large-device) {
				text-align: initial;
			}
		}
	}
}
