@import '../../partials/variables';
@import '../../partials/mixins';

#itinerary-container {
	justify-content: center;
	padding-top: 30px;

	.hide-map {
		display: none;
	}
	.hero-title {
		line-height: 29px;
	}

	.section-side {
		position: relative;
		min-height: 450px;

		&.right-side {
			margin-top: 25px;
			text-align: left;
		}
	}

	hr {
		width: calc(100% + 50px);
		margin-left: -25px;
	}

	.itinerary-main-info {
		margin: 24px 0;
		text-align: left;

		.itinerary-container {
			@include flexRow(normal, space-around, wrap);
		}
	}

	.primary-heading {
		text-align: left;
		font-weight: bold;
		font-size: 24px;
		color: $primary-red;
	}

	.itinerary-days-container {
		@include flexRow(normal, space-evenly);
	}

	@media (min-width: $small-device) {
		.section-hero {
			height: 300px !important;

			.hero-title {
				font-size: 30px !important;
				line-height: 44px;
			}
		}

		hr {
			width: auto;
			margin-left: auto;
		}

		.itinerary-main-info {
			margin: 40px 0;
		}

		.itinerary-days-container {
			justify-content: flex-start;
		}
	}

	@media (min-width: $medium-device) {
		hr {
			width: calc(100% + 50px);
			margin-left: -25px;
		}

		.section-hero {
			padding: 70px 90px;
		}
	}

	@media (min-width: $large-device) {
		.section-side {
			width: 50%;

			&.right-side {
				margin-top: 0;
				padding-left: 80px;
			}
		}
	}

	@media (max-width: $small-device) {
		.section-container {
			box-shadow: none;
		}

		.itinerary-container {
			justify-content: center;
		}

		.suggestions-container {
			.suggestion-title {
				font-size: 20px;
			}
		}

		#itinerary-builder-container {
			.heading {
				text-align: center;
			}
			.sub-heading {
				text-align: center;
			}
			.save-itinerary {
				justify-content: center;
			}
		}
	}
}
