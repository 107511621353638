@import '../../partials/variables';
@import '../../partials/mixins';

.pagination {
	@include flexRow(center, center);
	width: 100%;
	margin-top: 30px;

	> span {
		@include flexRow();
		color: $primary-red;
		font-size: 26px;
		cursor: pointer;
	}

	.pagination-numbers-container {
		@include flexRow();
		padding: 0 10px;

		.pagination-number:not(:last-child) {
			margin-right: 15px;
		}
	}

	.pagination-number {
		@include flexRow(center, center);
		width: 30px;
		height: 30px;
		background: $primary-red;
		border-radius: 100%;
		border: 2px solid $primary-red;
		color: white;
		cursor: pointer;

		&.active {
			background: white;
			span {
				color: black;
			}
		}

		span {
			font-weight: 600;
			font-size: 11px;
			color: white;
		}
	}

	@media (min-width: $large-device) {
		.arrow-right {
			@include absolute(50%, 0, auto, auto);
		}

		.arrow-left {
			@include absolute(50%, auto, auto, 0);
		}
	}
}
