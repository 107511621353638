@import '../../../partials/mixins';

.time-bar-container {
	@include flexRow(center, center);
	margin-bottom: 20px;
	margin-top: 30px;

	.bar-text {
		@include flexRow(center, center);
		position: absolute;
		width: 100%;

		span {
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			text-align: center;

			color: #ffffff;
		}
	}

	.progress {
		height: 24px;
		border-radius: 20px;
		border: 1px solid #50555c;
		background: white;
		width: 80%;

		.progress-bar {
			background: #50555c;
			border-radius: 20px;
		}
	}
}
