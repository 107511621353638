@import '../../partials/variables';

#interests-columns-container {
	padding: 30px;
	min-height: 600px;
	background-size: cover;

	.interests-columns-header {
		text-align: center;
		margin-bottom: 30px;
	}

	.interests-columns-title {
		font-weight: 800;
		font-size: 40px;
		color: $primary-red;
	}

	.interests-column-intro {
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		max-width: 680px;
		margin: 0 auto;
	}

	.interests-columns {
		display: flex;
		height: 100%;

		ul {
			padding-left: 0;
		}

		.interests-column {
			display: flex;
			flex-direction: column;
			width: 25%;
			padding: 30px 25px 0px;
			background-color: hsla(0, 0%, 94.9%, 0.9);
			border-radius: 10px;

			&:not(:last-child) {
				margin-right: 20px;
			}

			img {
				width: 60px;
				align-self: center;
			}
		}

		.category-title {
			text-align: center;
			font-weight: bold;
			margin: 15px 0 30px 0;
		}

		.interest-container {
			margin-bottom: 20px;
			display: flex;
			align-items: center;

			label {
				margin-bottom: 0;
				margin-left: 10px;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
			}

			input {
				width: 15px;
				height: 15px;
			}
		}
	}
}
