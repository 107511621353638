@import '../../partials/variables';
@import '../../partials/mixins';

#interests-grid {
	@include flexRow(normal, center, wrap);
	margin: 15px 0;

	button.interest-button {
		@include button(
			white,
			$primary-red,
			none,
			10px,
			20px,
			calc(50% - 14px)
		);
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
		font-size: 12px;
		margin: 7px;
		min-height: 130px;

		svg {
			margin-right: 0;
			margin-bottom: 10px;
		}

		&.active {
			@include color($primary-red, white, none);
		}

		@media screen and (min-width: $small-device) {
			width: calc(32% - 9px);
		}

		@media screen and (min-width: $large-device) {
			width: 159px;
		}
	}
}
