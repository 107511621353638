@import '../../partials/variables';
@import '../../partials/mixins';

.itinerary-day {
	@include flexColumn();

	button.itinerary-day-button {
		width: 65px;
		height: 65px;
		background-color: $primary-gray;
		font-weight: normal;
		font-size: 12px;
		border-radius: 8px;
		border: none;
		padding: 7px;

		&:focus {
			box-shadow: none !important;
		}

		&:active {
			background-color: black !important;

			.day-number {
				color: $primary-red;
			}

			.day-date {
				color: white !important;
			}
		}

		.day-date {
			font-size: 11px;
			line-height: 13px;
			color: black;
		}

		.day-number {
			color: black;
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 4px;
		}

		&.active {
			background-color: black !important;

			.day-number {
				color: $primary-red;
			}

			.day-date {
				color: white;
			}
		}
	}

	.day-activities {
		@include absolute(70px, auto, 132px, 0);
		display: none;
		margin-top: 45px;
		width: 100%;
		height: 50%;
	}

	&.selected {
		.day-activities {
			display: block;
		}
	}

	@media (min-width: $small-device) {
		button.itinerary-day-button {
			width: 105px;
			height: 75px;
			padding: 7px 20px;

			&:not(:last-child) {
				margin-right: 65px;
			}
		}
	}

	@media (min-width: $large-device) {
		.day-activities {
			padding-left: 80px;
		}
	}
}
