@import '../../partials/variables';

p.explanatory-text {
	margin-top: 15px;

	a {
		color: $primary-red;
		font-weight: bold;
		text-decoration: underline;
		font-size: 16px;

		&:hover {
			color: $primary-red;
		}
	}
}
