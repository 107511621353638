#group-size-box-container {
	justify-content: space-around;
	max-width: 365px;
	width: 100%;

	.number-people-box {
		height: 62px;
		z-index: 1;
		margin-bottom: 10px;

		span {
			text-transform: capitalize;
		}

		// &:hover {
		// 	box-shadow: 0px 0px 7px $primary-red;
		// }
	}

	.description {
		display: none;
		margin-top: -15px;
		margin-bottom: 10px;
		padding: 20px 15px 15px 15px;
		background: #eeeeee;
		font-size: 10px;
		font-style: italic;
		line-height: 12px;
		text-align: left;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		width: 100%;

		&.active {
			display: block;
		}
	}
}
