@import '../../partials/variables';
.share-link-container {
	display: flex;
	flex-direction: column;
	padding: 40px;
	width: 586px;
	height: 650px;
	.title-share-link {
		font-weight: bold;
		padding: 10px;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: $primary-red;
		margin-top: 20%;
	}
	.subtitle-share-link {
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: black;
		margin: 10px 0;
	}
	.share-controls {
		display: flex;
	}
	.share-alert {
		font-weight: bold;
		font-size: 18px;
		color: $primary-red;
		text-align: center;
	}
	.share-input {
		height: 53px;
		width: 491px;
		background-color: $secondary-gray;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: $primary-red;
		border-radius: 10px 0px 0px 10px;
		border: none;
		padding: 15px 20px;
	}
}
