@import '../../partials/mixins';

.itinerary-user-bar {
	@include flexRow(normal, space-between);
	margin-bottom: 25px;

	.user-role {
		margin-top: 5px;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		text-align: left;
	}

	.user-contact {
		@include flexRow();

		.user-photo {
			margin-right: 18px;
		}
	}

	@media (min-width: $small-device) {
		margin-bottom: 45px;

		.user-role {
			font-size: 30px;
			line-height: 37px;
		}

		.user-contact {
			.user-photo {
				margin-right: 30px;
			}
		}

		.user-image {
			width: 60px !important;
			height: 60px !important;
		}
	}
}
