@import '../../partials/variables';

#itinerary-utility-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 45px;

	.sub-heading {
		max-width: 760px;
		margin-left: auto;
		margin-right: auto;
	}

	.section-container {
		box-shadow: none;

		.left-side {
			border-color: black;
			border-width: 1px;
		}
	}
}
