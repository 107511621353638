@import '../../partials/mixins';
@import '../../partials/variables';

.chat-widget {
	cursor: pointer;

	.widget-icon {
		@include flexRow(center, center);
		border-radius: 100%;
	}

	.widget-text {
		font-size: 11px;
		margin-top: 6px;
	}

	@media (min-width: $small-device) {
		.widget-icon {
			width: 60px !important;
			height: 60px !important;
		}
	}
}
