.header {
	background: #fff;
	// border-bottom: 1px solid #f5f5f5;
	// padding: 1em;

	&--nav {
		background: #fff;
		margin: 0;
		text-align: center;
		overflow: auto;

		height: 100%;
		width: 100%;
		position: fixed;
		z-index: 3;
		bottom: -100%;
		left: 0;
		transition: 0.5s ease-out;

		a {
			display: block;
		}
	}
}

.active .header--nav {
	bottom: -0%;
}

.open-menu {
	margin-right: 1em;
}

.close-menu {
	margin-bottom: 1em;
}
