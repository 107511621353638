@import '../../partials/variables';

#activity-filters-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;

	select {
		padding-left: 20px;
	}

	.activities-filter {
		height: 65px;
		min-width: 190px;
		border-radius: 10px;
		background-color: #c4c4c4;
		font-size: 20px;
		border: none;

		&:not(:last-child) {
			margin-right: 25px;
		}

		&.selected {
			background-color: $primary-red;
			color: white;
		}
	}

	button {
		font-weight: normal;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		span {
			font-size: 10px;
		}
	}
}
