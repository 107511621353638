@import '../../partials/mixins';

.brand-header {
	@include flexRow(normal, center);
	background-image: url('../../assets/brand/brand_header_background.png');
	background-size: cover;
	height: 135px;

	img {
		margin-top: 37px;
		height: 40px;
	}
}
