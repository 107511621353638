@import '../../partials/mixins';

.gallery-grid {
	@include flexRow(normal, normal, wrap);

	.gallery-image {
		width: calc(33% - 10px);
		height: 30vw;
		margin: 0 5px 10px 5px;
		background-size: cover;
		background-position: center;
		cursor: pointer;

		&:last-child {
			margin-right: 0;
		}
	}
}
