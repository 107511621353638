@import '../../partials/variables';

.itinerary-field {
	width: 40%;
	height: 100%;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		display: flex;
		justify-content: center;
		margin-bottom: 5px;
	}

	input {
		width: 100%;
		padding: 7px 11px;
		background-color: #f2efef;
		border-radius: 8px;
		text-align: left;
		border: none;
		font-size: 11px;
	}

	textarea {
		background-color: $primary-gray;
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 8px;
		padding: 11px;
	}

	div {
		color: $primary-red;
		font-size: 12px;
	}

	@media (min-width: $small-device) {
		label {
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		input {
			font-size: 16px;
			padding: 10px 15px;
		}
	}
}
