@import '../../partials/variables';
@import '../../partials/mixins';

.pictures-container {
	ul {
		@include flexRow(normal, flex-start, wrap);
		padding: 0;
		width: 100%;
	}

	.sortable-picture {
		@include flexRow(center, center);
		position: relative;
		width: calc(50% - 10px);
		height: calc(50vw - 10px);
		background-size: cover;
		background-position: center;
		margin: 5px;
		cursor: grab;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		svg {
			cursor: pointer;
			color: $primary-red;
		}

		@media (min-width: $medium-device) {
			width: 32%;
			height: 300px;
		}
	}
}

.file-uploader {
	text-align: right;
	color: $primary-red;
	font-weight: bold;
	font-size: 11px;

	span {
		cursor: pointer;
	}

	svg {
		cursor: pointer;
		margin-left: 5px;
	}
}
