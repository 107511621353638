@import '../../../partials/variables';
@import '../../../partials/mixins';

#create-activity-container {
	padding: 30px;
	max-width: 900px;
	margin: 0 auto;

	.create-activity-header {
		margin: 20px;
		.create-activity-title {
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 37px;
			text-align: center;
			margin-top: 20px;
		}
	}
	.half-container {
		@include flexRow(normal, space-between, wrap);
		.half-input {
			width: 50%;
		}
	}

	form {
		line-height: 20px;
		margin-top: 30px;
		.row {
			margin-bottom: 50px;
		}
		.optional {
			font-style: normal;
			font-weight: 300;
			font-size: 11px;
			line-height: 13px;
			width: 100%;
			text-align: left;
			color: #929292;
			margin-top: -6px;
		}
		label {
			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			text-align: left;
			color: #000000;
			width: 100%;
			margin-top: 15px;
		}
		input {
			background: #f7f7f7;
			border-radius: 7px;
			border: none;
		}
		textArea {
			background: #f7f7f7;
			border-radius: 7px;
			width: 100%;
			border: #f7f7f7;
			padding: 15px;
		}
		textArea:focus {
			background: white;
			border-color: #80bdff;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
				0 0 8px rgba(0, 123, 255, 0.6);
			outline: 0 none;
		}
		button {
			border-radius: 10px;
			height: 40px;
			align-items: center;
			font-size: 14px;
			padding: 10px;
		}
		.form-error {
			text-align: left;
			color: red;
			font-weight: bold;
			width: 100%;
		}
		.upload-img {
			display: flex;
			justify-content: center;
			background: #c4c4c4;
			border-radius: 10px;
			width: 100%;
			height: 37px;
			padding: 10px;
			cursor: pointer;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;
			margin-top: 10px;
			color: #ffffff;
			div {
				margin-right: 10px;
			}
		}
		.upload-img-active {
			background: #3f3f3f;
		}
		.gallery-preview-container {
			display: flex;
			img {
				width: 100px;
				height: 100px;
			}
			svg:hover {
				cursor: pointer;
				color: $primary-red;
			}
		}
		.switch-container {
			display: flex;
			justify-content: space-between;
			width: 76%;
			text-align: justify;
			flex-wrap: wrap;

			.switch-description {
				width: 213px;
				font-weight: 300;
				font-size: 10px;
				line-height: 12px;

				color: #929292;
			}
		}
	}
	.field-array-controls {
		@include flexRow(normal, flex-end);
		color: $primary-red;
		cursor: pointer;
		.container-icon {
			@include flexRow(center, center);
			border: 1px solid red;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			background-color: rgba(255, 255, 255, 0.5);
			cursor: pointer;
			color: $primary-red;
			margin-left: 10px;
		}
	}
	.field-array-container {
		@include flexRow(normal, normal, wrap);

		input {
			width: 90%;
		}
		.container-icon {
			align-self: center;
			width: 10%;
			cursor: pointer;
			color: #ea0029;

			font-size: 20px;
		}
	}
	input[type='time']::-webkit-datetime-edit-ampm-field {
		display: none;
	}
	.custom-time-picker {
		display: table;
		.react-time-picker__wrapper {
			border: none;
			width: 16px;
		}
		.react-time-picker__inputGroup {
			border-radius: 7px;
			padding: 1px 5px 16px;
			background: #f7f7f7;
		}
		button {
			display: none;
		}
		input {
			margin: 5px 0;
		}
	}

	@media (max-width: $small-device) {
		form button {
			margin-top: 20px;
		}

		.create-activity-header {
			.create-activity-title {
				font-size: 30px;
			}
		}
		.switch-container {
			.switch-description {
				width: 100% !important;
			}
		}
	}
}
