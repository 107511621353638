@import '../../partials/variables';
@import '../../partials/mixins';

.interests-box-container {
	@include flexColumn(flex-start);
	align-self: flex-start;
	text-align: left;
	width: 100%;
	border: 1px solid $primary-red;
	border-radius: 10px;
	border-top-left-radius: 0;
	padding: 7px 10px;
	margin-bottom: 10px;
	height: auto;
	max-height: 70px;

	> span {
		font-weight: 600;
		font-size: 10px;
		color: $primary-red;
	}

	p {
		font-size: 9px;
		line-height: 11px;
		margin: 0;
	}
}
