@import '../../partials/variables';

#activity-suggestions-container {
	position: relative;
	width: 100%;
	margin-top: 28px;

	.suggestion-title {
		text-align: left;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 37px;
		color: #000000;
	}

	.suggestions-info-container {
		margin-top: 30px;
	}

	.suggestion-cards-container::-webkit-scrollbar {
		display: none;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.suggestion-cards-container {
		display: flex;
		overflow: scroll;
		max-width: 100%;
		white-space: nowrap;
		margin-top: 30px;
		padding: 5px;

		.suggestions-right-arrow {
			display: flex;
			align-items: center;
			position: absolute;
			height: 80%;
			top: 20%;
			right: -15px;
			background: linear-gradient(
				270deg,
				#ffffff 11.39%,
				#ffffff 40.94%,
				rgba(255, 255, 255, 0.36) 62.28%,
				rgba(255, 255, 255, 0) 88.55%,
				rgba(255, 255, 255, 0) 116.46%
			);
			padding-left: 55px;
			color: #c4c4c4;
			cursor: pointer;

			svg {
				width: 13px;
				height: 25px;
			}
		}
	}

	@media (max-width: $small-device) {
		width: calc(100% + 45px);

		.suggestion-cards-container {
			margin-top: 15px;
			.suggestions-right-arrow {
				display: none;
			}
		}

		.suggestion-title {
			font-size: 20px;
		}
	}
}
