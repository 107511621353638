@import '../../partials/variables';
@import '../../partials/mixins';

#suggestion-card-container {
	&.utility-layout {
		position: relative;
		display: flex;
		max-width: unset;
		max-height: unset;
		min-height: 135px;
		margin-bottom: 20px;
		margin-right: 0;

		.suggestion-img {
			height: auto;
			width: 60%;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;

			.suggestion-add-icon {
				position: absolute;
				left: auto;
				top: auto;
				right: 15px;
				bottom: 15px;
				width: 25px;
				height: 25px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-color: $primary-red;
				border-radius: 100%;
				background-color: rgba(234, 0, 41, 0.3);
			}
		}

		.suggestion-info {
			padding-left: 25px;
			width: 40%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;

			.suggestion-title {
				text-align: left;
			}
		}

		.see-more-button {
			svg {
				margin-left: 5px;
			}
		}
	}

	background: #ffffff;
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	min-height: 230px;
	min-width: 140px;
	max-width: 150px;
	margin-right: 10px;
	cursor: pointer;

	.suggestion-img {
		width: 100%;
		height: 130px;
		background-size: cover;
		padding: 10px;

		.suggestion-add-icon {
			border: 1px solid red;
			border-radius: 50%;
			width: 20%;
			background-color: rgba(255, 255, 255, 0.5);
			cursor: pointer;
			color: $primary-red;
		}
	}

	.suggestion-info {
		display: flex;
		align-content: space-between;
		flex-wrap: wrap;
		padding: 15px;

		.suggestion-title {
			font-weight: 600;
			font-size: 15px;
			line-height: 18px;
			color: #000000;
			white-space: normal;
			overflow: hidden;
		}

		.suggestion-hour {
			font-weight: normal;
			font-size: 13px;
			line-height: 16px;
			margin-top: 5px;
			color: #000000;
			text-align: left;
		}
	}
}
