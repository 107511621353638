@import '../../partials/variables';

#new-trip-container {
	#step-1 {
		.options-container {
			align-items: flex-start;
		}

		.country-box {
			width: calc(25% - 10px);
			max-width: 170px;
			height: 100px;

			@media (max-width: $extra-small-device) {
				width: 100%;
				max-width: none;
				margin-bottom: 10px;
			}

			@media (min-width: $extra-small-device) and (max-width: $medium-device) {
				width: calc(50% - 10px);
				margin-bottom: 10px;

				&:nth-child(3),
				&:last-child {
					margin-bottom: 0;
				}
			}

			@media (min-width: $large-device) {
				width: calc(50% - 10px);
			}
		}
	}
}
