@import '../../partials/variables';
@import '../../partials/mixins';

.interests-bars-container {
	width: 100%;
	max-width: 400px;

	.interests-row {
		@include flexRow(normal, space-between, wrap);

		.main-interests-column {
			display: grid;
			justify-items: center;
			width: calc(50% - 10px);
		}
		.secondary-interests-column {
			display: grid;
			justify-items: center;
			width: calc(50% - 10px);
		}
	}

	.main-title {
		font-weight: 900;
		font-size: 18px;
	}
}
