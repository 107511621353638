.leaflet-container {
	width: 100%;
	height: 200px;
}

//hack: hide the instruction box /09-07-2020 the library dosent have a native solution
.leaflet-control-container .leaflet-routing-container-hide {
	display: none;
}

.leaflet-control-container .leaflet-routing-alternatives-container {
	display: none;
}

#leaflet-map .search-control-icon-button {
	padding: 0px;
	padding-left: 5px;
	margin: 0;
	.search-control-input {
		font-family: 'Montserrat';
		background-color: white;
	}
}
