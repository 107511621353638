@import '../../partials/variables';

#activities-container {
	width: 100vw;
	margin-left: -45px;
	max-height: 236px;
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: 20px;

	ul {
		padding-left: 0;
	}

	.button-primary {
		font-weight: bold;
	}

	@media (min-width: $small-device) {
		width: 100%;
		margin-left: auto;
		margin-top: 30px;
	}
}
