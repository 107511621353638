@import '../../partials/variables';

#ambassador-short-info-container {
	margin: 15px 0;
	text-align: center;

	.user-photo {
		margin-bottom: 15px;
	}

	span {
		color: $primary-red;
		font-size: 24px;
		font-weight: bold;
	}
}
