@import '../../partials/variables';
@import '../../partials/mixins';

.data-span {
	@include flexRow(center);
	color: #929292;
	font-size: 13px;
	line-height: 23px;
	cursor: default;
	text-align: left;

	svg {
		min-width: 25px;
		color: $primary-red;
		margin-right: 10px;
	}

	@media (max-width: 992px) {
		font-size: 11px;
	}

	@media (max-width: 500px) {
		line-height: 20px;
	}
}
