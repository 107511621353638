@import '../../partials/mixins';

#redknot-verified-badge {
	@include flexRow(center);

	span {
		font-size: 11px;
		font-weight: normal;
		margin-left: 5px;
		line-height: 20px;
	}
}
