#selected-activities-list-container {
	.activities-container {
		margin-bottom: 25px;

		ul {
			padding: 0;
		}
	}

	.builder-heading {
		display: flex;
		flex-direction: column;

		.builder-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.builder-actions {
			display: flex;

			> div {
				margin-right: 22px;
				cursor: pointer;
			}

			svg {
				margin-right: 12px;
			}

			span {
				font-weight: 600;
			}
		}

		.builder-info {
			margin-top: 10px;
			text-align: left;
		}

		.builder-time {
			p {
				font-size: 10px;
			}
		}

		.builder-empty {
			font-size: 20px;
		}
	}

	.see-more-button {
		font-size: 14px;
		text-align: right;

		svg {
			margin-left: 5px;
		}
	}
}
