.modal {
	@media (min-width: 992px) {
		.custom-modal {
			width: 100%;
		}
	}

	@media (min-width: 768px) {
		.custom-modal modal-dialog {
			width: 100%;
			height: auto;
			margin: 0;
			padding: 0;
		}
	}
	@media (min-width: 768px) {
		.modal-content {
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
			height: auto;
			min-height: 100%;
			border-radius: 0;
		}
	}
	@media (min-width: 576px) {
		.custom-modal {
			box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
			height: auto;
			max-width: 100%;
			border-radius: 0;
		}
	}
}

.close-container {
	width: 100%;
	margin: 22px 0px 0px -22px;
	display: flex;
	justify-content: flex-end;
}
