@import '../../partials/mixins';
@import '../../partials/variables';
.print-actions-container {
	.print-title {
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		color: $primary-red;
		font-weight: 600;
	}

	.print-action-controls {
		display: flex;
	}

	.send-itinrerary-text {
		margin-top: 20px;
		margin-bottom: 20px;
		width: 442px;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: $primary-gray;
	}
	@include flexColumn(center, center);
	input {
		margin-bottom: 10px;
	}
}
