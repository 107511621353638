@import '../../partials/variables';
@import '../../partials/mixins';

#booking-container {
	margin-top: 30px;

	.ambassador-container {
		text-align: left;

		.heading {
			font-size: 12px;
			font-weight: 600;
		}

		.ambassador-info {
			@include flexRow(center);
			margin: 10px 0;
			background-color: #f4f4f4;
			border-radius: 5px;
			padding: 12px;
		}

		.ambassador-profile {
			@include flexColumn(flex-start);
			margin-left: 13px;

			div:first-child {
				font-weight: 700;
				font-size: 12px;
			}

			div:last-child {
				@include text(500, $primary-red, left, 12px, underline);
				cursor: pointer;
			}
		}
	}

	.details-container {
		margin-bottom: 20px;

		input[type='email'] {
			min-width: 237px;
		}

		.dates-container {
			text-align: right;
		}

		.promo-code {
			@include flexRow(center, flex-end);
			input {
				font-weight: 700;
			}
		}

		.group-size-inputs-container {
			@include flexColumn();
		}
	}

	.price-container {
		margin: 30px 0 7px 0;
	}

	.total-price {
		text-align: right;
		color: black;

		span {
			font-size: 1.3rem;
		}
	}

	.valid-booking-actions {
		button {
			margin-bottom: 5px;

			&:active:focus {
				box-shadow: none;
			}
		}

		a {
			text-decoration: none;
			color: $primary-red;
			font-weight: bold;
			font-size: 12px;

			&:hover {
				color: $primary-red;
				text-decoration: none;
				font-size: 1rem;
			}
		}

		.side-note {
			width: 100%;
			font-size: 12px;
		}
	}
}
