@import '../../partials/variables';
.notification-container {
	border: 2px solid $primary-red;
	border-radius: 5px;
	background-color: white;
	position: fixed;
	padding: 10px;
	width: 250px;
	.notification-message {
		font-size: 18px;
		text-align: center;
		color: $primary-red;
	}
}

.top-center {
	top: 12px;
	left: 0;
	right: 0;
	margin: 0 auto;
	transition: transform 0.6s ease-in-out;
	animation: toast-in-Top 0.7s;
}

.bottom-center {
	bottom: 12px;
	left: 0;
	right: 0;
	margin: 0 auto;
	transition: transform 0.6s ease-in-out;
	animation: toast-in-Top 0.7s;
}

.top-right {
	top: 12px;
	right: 12px;
	transition: transform 0.6s ease-in-out;
	animation: toast-in-right 0.7s;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform 0.6s ease-in-out;
	animation: toast-in-right 0.7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform 0.6s ease-in;
	animation: toast-in-left 0.7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform 0.6s ease-in;
	animation: toast-in-left 0.7s;
}

@keyframes toast-in-right {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes toast-in-Top {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateX(0);
	}
}
