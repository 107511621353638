@import '../../partials/variables';
@import '../../partials/mixins';

.interviewed-by-container {
	margin-top: 10px;
	font-size: 11px;

	a {
		@include flexRow(normal, center);
	}

	.user-photo {
		margin-left: 10px;
	}
}
