@import '../../partials/mixins';

.trip-item {
	@include flexRow(center, space-between);
	height: 125px;
	padding: 20px;
	border-radius: 10px;
	// background-position: bottom 178px right 0;
	background-position: center;
	background-size: cover;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	&:not(:last-child) {
		margin-bottom: 25px;
	}

	.trip-details {
		@include flexColumn();
		font-size: 18px;
		font-weight: bold;
		line-height: 22px;
		color: white;
	}

	.trip-dates {
		font-size: 11px;
		line-height: 13px;
		font-weight: normal;
		margin-top: 5px;
	}
}
