@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800|Open+Sans&display=swap);
@media screen and (min-width: 576px) {
  #google_translate_element {
    position: absolute;
    top: 12px;
    right: 16px;
    bottom: auto;
    left: auto; } }

.goog-te-banner-frame.skiptranslate {
  display: none !important; }

.goog-te-combo,
.goog-te-banner *,
.goog-te-ftab *,
.goog-te-menu *,
.goog-te-menu2 *,
.goog-te-balloon * {
  font-family: arial;
  font-size: 10pt;
  background-repeat: no-repeat;
  text-indent: 20px;
  background-color: white;
  color: black !important; }

.goog-logo-link {
  display: none !important; }

.goog-te-gadget {
  color: transparent !important; }

.goog-te-gadget .goog-te-combo {
  margin: 2px 0 !important; }

#redknot-brand {
  width: 185px;
  cursor: pointer; }

#navbar button.navbar-toggler {
  padding: 0;
  margin: 0 !important; }
  #navbar button.navbar-toggler span {
    font-size: 20px; }

#navbar .agent {
  background-color: darkslateblue; }

#navbar nav {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 1px 0px 11px lightgray;
  background-color: white;
  z-index: 3; }
  #navbar nav .navbar-toggler {
    border: none;
    outline: none; }
  #navbar nav .navbar-collapse {
    background-color: black;
    margin: 0.5rem -1rem -0.5rem -1rem; }
  #navbar nav li {
    width: 100%;
    cursor: pointer; }
    #navbar nav li a {
      color: white;
      margin: 18px 0; }
    #navbar nav li a:hover {
      color: #ea0029;
      font-weight: bold;
      text-decoration: none; }
  #navbar nav .nav-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    height: 73px;
    padding: 17px;
    background-color: #1b1b1b; }
    #navbar nav .nav-footer a {
      font-weight: bold;
      color: #ea0029; }
    #navbar nav .nav-footer .nav-item {
      max-width: 50%; }
      #navbar nav .nav-footer .nav-item:nth-child(2) {
        border-left: solid 1px #2c2c2c; }

#navbar .go-back-icon svg {
  font-size: 26px;
  cursor: pointer; }

#loader-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  height: calc(100vh - 140px);
  width: 100%; }
  #loader-container .spinner-border {
    width: 5rem;
    height: 5rem;
    color: #ea0029;
    border: 0.4em solid currentColor;
    border-right-color: transparent; }

.brand-header {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: center;
  flex-wrap: nowrap;
  background-image: url(/static/media/brand_header_background.d6157537.png);
  background-size: cover;
  height: 135px; }
  .brand-header img {
    margin-top: 37px;
    height: 40px; }

.user-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap; }
  .user-photo .user-image {
    position: relative;
    border: none;
    background-size: cover; }
  .user-photo .redknot-verified-logo {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 15px;
    left: auto;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-position: top; }
    @media (max-width: 992px) {
      .user-photo .redknot-verified-logo {
        display: none; } }
  .user-photo .user-name {
    font-size: 11px;
    margin-top: 6px; }

.gallery-grid {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: normal;
  flex-wrap: wrap; }
  .gallery-grid .gallery-image {
    width: calc(33% - 10px);
    height: 30vw;
    margin: 0 5px 10px 5px;
    background-size: cover;
    background-position: center;
    cursor: pointer; }
    .gallery-grid .gallery-image:last-child {
      margin-right: 0; }


.user-profile-field {
  text-align: left; }
  .user-profile-field label {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 600; }

#profile-container {
  margin-top: 35px; }
  #profile-container .fields-container {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
    flex-wrap: nowrap;
    margin-top: 15px; }
    #profile-container .fields-container .main-fields p {
      text-align: center;
      margin: 0; }
    #profile-container .fields-container .city-field {
      color: #ea0029; }
    #profile-container .fields-container .country-field {
      font-size: 12px; }
    #profile-container .fields-container .name-field {
      color: black;
      font-weight: 900;
      font-size: 22px;
      margin-top: 10px; }
    #profile-container .fields-container .bio-field {
      margin-top: 16px;
      text-align: justify;
      -moz-text-align-last: center;
           text-align-last: center; }
  #profile-container .actions-container {
    margin-top: 1rem; }
  #profile-container button {
    margin-bottom: 15px; }
  #profile-container .tariff {
    text-align: right;
    padding: 25px 0; }
    #profile-container .tariff .amount {
      font-weight: 900;
      font-size: 18px; }

.alert {
  font-size: 12px;
  margin-top: 1rem;
  text-align: center; }

.example-day-entry {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: normal;
  flex-wrap: nowrap;
  margin-bottom: 10px; }
  .example-day-entry input[type='time'] {
    margin-right: 10px;
    max-width: 115px;
    cursor: text; }

.pictures-container ul {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  width: 100%; }

.pictures-container .sortable-picture {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  width: calc(50% - 10px);
  height: calc(50vw - 10px);
  background-size: cover;
  background-position: center;
  margin: 5px;
  cursor: -webkit-grab;
  cursor: grab; }
  .pictures-container .sortable-picture:first-child {
    margin-left: 0; }
  .pictures-container .sortable-picture:last-child {
    margin-right: 0; }
  .pictures-container .sortable-picture svg {
    cursor: pointer;
    color: #ea0029; }
  @media (min-width: 768px) {
    .pictures-container .sortable-picture {
      width: 32%;
      height: 300px; } }

.file-uploader {
  text-align: right;
  color: #ea0029;
  font-weight: bold;
  font-size: 11px; }
  .file-uploader span {
    cursor: pointer; }
  .file-uploader svg {
    cursor: pointer;
    margin-left: 5px; }

.user-edit-field-input {
  text-align: left;
  margin: 20px 0; }
  .user-edit-field-input:first-child {
    margin-top: 10px; }
  .user-edit-field-input.photo-input {
    text-align: center; }
  .user-edit-field-input label {
    font-weight: 600;
    font-size: 12px; }
  .user-edit-field-input select,
  .user-edit-field-input input {
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    height: 42px;
    padding-left: 12px;
    padding-right: 12px; }
    .user-edit-field-input select:focus,
    .user-edit-field-input input:focus {
      box-shadow: none; }
  .user-edit-field-input input {
    padding-left: 12px;
    padding-right: 12px;
    background-color: #f7f7f7;
    border: none;
    border-radius: 5px; }
    .user-edit-field-input input:focus {
      background-color: #f7f7f7; }
  .user-edit-field-input textarea {
    width: 100%;
    height: 100px;
    font-size: 12px;
    font-weight: 500;
    color: #050505;
    background-color: #f7f7f7;
    border: none;
    border-radius: 5px;
    padding: 13px; }
  .user-edit-field-input .bday-container {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 70%; }
    .user-edit-field-input .bday-container input {
      height: 42px;
      width: 84px;
      text-align: center;
      margin-right: 5%; }

#profile-edit-container {
  margin-top: 35px; }
  #profile-edit-container form {
    flex-direction: column; }
  #profile-edit-container .profile-photo {
    position: relative;
    cursor: pointer; }
    #profile-edit-container .profile-photo .upload-photo-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      height: 43px;
      width: 43px;
      background-color: #ea0029;
      border-radius: 100%;
      color: white;
      cursor: pointer; }
  #profile-edit-container .edit-fields {
    margin-top: 30px; }

#auth-container {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 30px;
  margin: 30px auto;
  max-width: 550px; }
  #auth-container .auth-provider-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border: none;
    padding: 15px;
    margin-bottom: 10px;
    color: black;
    width: 100%;
    background: -webkit-linear-gradient(98.52deg, #ffffff 0%, #eeeeee 100%);
    background: linear-gradient(-8.52deg, #ffffff 0%, #eeeeee 100%);
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.07);
    border-radius: 5px; }
    #auth-container .auth-provider-button img {
      margin-right: 10px;
      height: 18px; }
  #auth-container .auth-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 18px 0; }
  #auth-container .auth-divider-line {
    width: 42%; }
  #auth-container .auth-divider-o {
    font-family: 'Open Sans';
    font-weight: normal; }
  #auth-container .auth-fields {
    margin: 4px 0 30px 0; }
    #auth-container .auth-fields .input-group:not(:first-child) {
      margin-top: 15px; }
  #auth-container .auth-actions button {
    margin: 5px 0; }
  #auth-container .auth-actions .other-actions {
    margin-top: 10px; }
    #auth-container .auth-actions .other-actions u {
      cursor: pointer; }
  @media (min-width: 576px) {
    #auth-container {
      padding: 0; } }

#new-trip-container #step-1 .options-container {
  align-items: flex-start; }

#new-trip-container #step-1 .country-box {
  width: calc(25% - 10px);
  max-width: 170px;
  height: 100px; }
  @media (max-width: 320px) {
    #new-trip-container #step-1 .country-box {
      width: 100%;
      max-width: none;
      margin-bottom: 10px; } }
  @media (min-width: 320px) and (max-width: 768px) {
    #new-trip-container #step-1 .country-box {
      width: calc(50% - 10px);
      margin-bottom: 10px; }
      #new-trip-container #step-1 .country-box:nth-child(3), #new-trip-container #step-1 .country-box:last-child {
        margin-bottom: 0; } }
  @media (min-width: 992px) {
    #new-trip-container #step-1 .country-box {
      width: calc(50% - 10px); } }

#new-trip-container #step-2 .city-container {
  width: 100%;
  max-width: 365px;
  height: calc(300px - 80px); }

#new-trip-container #step-2 .city-box {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-position: center;
  height: calc(50% - 10px); }

.group-size-inputs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
  font-style: normal;
  font-size: 20px; }
  .group-size-inputs-container p {
    font-size: 20px;
    font-weight: 600;
    width: 85%;
    text-align: left;
    margin: 0; }
  .group-size-inputs-container .number-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: normal;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    /* Firefox */ }
    .group-size-inputs-container .number-input span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      color: white;
      width: 52px;
      height: 52px;
      background: -webkit-linear-gradient(359.41deg, #ee315f 9.53%, #eb2237 70.25%), #ea0029;
      background: linear-gradient(90.59deg, #ee315f 9.53%, #eb2237 70.25%), #ea0029;
      cursor: pointer; }
    .group-size-inputs-container .number-input .btn-increase {
      border-radius: 0px 15px 15px 0px; }
      .group-size-inputs-container .number-input .btn-increase.disabled {
        opacity: 0.5; }
        .group-size-inputs-container .number-input .btn-increase.disabled .number-input span {
          cursor: default; }
    .group-size-inputs-container .number-input .btn-decrease {
      border-radius: 15px 0px 0px 15px; }
    .group-size-inputs-container .number-input .btn-increase,
    .group-size-inputs-container .number-input .btn-decrease {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }
    .group-size-inputs-container .number-input .num-travelers {
      width: 82px;
      height: 52px;
      font-size: 12px;
      text-align: center;
      color: #929292;
      border: none; }
    .group-size-inputs-container .number-input input:disabled {
      background: white;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1); }
    .group-size-inputs-container .number-input input::-webkit-outer-spin-button,
    .group-size-inputs-container .number-input input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .group-size-inputs-container .number-input input[type='number'] {
      -moz-appearance: textfield; }

#group-size-box-container {
  justify-content: space-around;
  max-width: 365px;
  width: 100%; }
  #group-size-box-container .number-people-box {
    height: 62px;
    z-index: 1;
    margin-bottom: 10px; }
    #group-size-box-container .number-people-box span {
      text-transform: capitalize; }
  #group-size-box-container .description {
    display: none;
    margin-top: -15px;
    margin-bottom: 10px;
    padding: 20px 15px 15px 15px;
    background: #eeeeee;
    font-size: 10px;
    font-style: italic;
    line-height: 12px;
    text-align: left;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%; }
    #group-size-box-container .description.active {
      display: block; }

@media (max-width: 320px) {
  #new-trip-container #step-3 .options-container {
    min-height: 470px; } }

@media (min-width: 320px) {
  #new-trip-container #step-3 .options-container {
    min-height: 420px; } }

@media (min-width: 992px) {
  #new-trip-container #step-3 .options-container {
    min-height: 430px;
    align-items: center; } }

#new-trip-container #step-4 .dates-container {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: center;
  flex-wrap: wrap;
  padding: 11px; }
  #new-trip-container #step-4 .dates-container button.no-dates-button {
    font-weight: 500;
    color: inherit;
    text-align: justify;
    font-size: 1rem;
    text-decoration: none;
    background-color: #c4c4c4;
    color: #929292;
    border: none;
    border-radius: 20px;
    padding: 15px 18px;
    width: 265px; }
    #new-trip-container #step-4 .dates-container button.no-dates-button.active {
      background-color: #ea0029;
      color: white;
      border: none;
      box-shadow: none; }
    #new-trip-container #step-4 .dates-container button.no-dates-button:focus {
      box-shadow: none; }

#new-trip-container #step-4 input {
  background-color: #c4c4c4;
  color: #777777;
  border: none;
  font-weight: 500;
  color: inherit;
  text-align: left;
  font-size: 1rem;
  text-decoration: none;
  width: 265px;
  height: 50px;
  padding: 15px 18px;
  border-radius: 10px; }
  #new-trip-container #step-4 input.filled {
    background-color: #ea0029;
    color: white;
    border: none; }

@media (max-width: 320px) {
  #new-trip-container #step-4 input {
    width: 100%; }
  #new-trip-container #step-4 .dates-container button.no-dates-button {
    width: 100%; } }

@media (min-width: 992px) {
  #new-trip-container #step-4 .dates-container {
    padding: 0; } }

#interests-grid {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px 0; }
  #interests-grid button.interest-button {
    background-color: white;
    color: #ea0029;
    border: none;
    border-radius: 10px;
    padding: 20px;
    width: calc(50% - 14px);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
    font-size: 12px;
    margin: 7px;
    min-height: 130px; }
    #interests-grid button.interest-button svg {
      margin-right: 0;
      margin-bottom: 10px; }
    #interests-grid button.interest-button.active {
      background-color: #ea0029;
      color: white;
      border: none; }
    @media screen and (min-width: 576px) {
      #interests-grid button.interest-button {
        width: calc(32% - 9px); } }
    @media screen and (min-width: 992px) {
      #interests-grid button.interest-button {
        width: 159px; } }

#new-trip-container #step-5 #loader-container {
  height: 100%; }

#new-trip-container #step-5.step-container {
  min-width: unset; }
  #new-trip-container #step-5.step-container .step-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; }
  @media (min-width: 320px) {
    #new-trip-container #step-5.step-container {
      padding: 30px 20px; } }
  @media (min-width: 576px) {
    #new-trip-container #step-5.step-container {
      padding: 30px 45px; } }
  @media (min-width: 992px) {
    #new-trip-container #step-5.step-container {
      min-width: 888px; }
      #new-trip-container #step-5.step-container .step-number {
        margin-bottom: 0; } }

#new-trip-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap; }

#new-trip-container .step-container {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 750px;
  min-height: 300px;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 30px 45px;
  margin-bottom: 30px; }
  #new-trip-container .step-container .step-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 15px; }
    #new-trip-container .step-container .step-title-container .step-title {
      text-align: left;
      text-align: initial;
      padding: 0; }
      #new-trip-container .step-container .step-title-container .step-title h2 {
        font-weight: bold;
        font-size: 18px;
        margin: 0;
        line-height: 22px; }
    #new-trip-container .step-container .step-title-container .step-number {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap; }
      #new-trip-container .step-container .step-title-container .step-number span {
        font-weight: bold;
        color: #ea0029;
        text-align: center;
        font-size: 18px;
        text-decoration: none;
        border: 3px solid #ea0029;
        border-radius: 100%;
        margin-right: 15px;
        width: 30px;
        height: 30px; }
  #new-trip-container .step-container .options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
    border-left: none; }
    #new-trip-container .step-container .options-container > [class*='-container'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%; }
    #new-trip-container .step-container .options-container [class*='-box'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
      border-radius: 10px;
      cursor: pointer; }
      #new-trip-container .step-container .options-container [class*='-box'].active {
        border: 2px solid #ea0029; }
      #new-trip-container .step-container .options-container [class*='-box'].inactive {
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%); }
      #new-trip-container .step-container .options-container [class*='-box'] span {
        margin: auto;
        font-weight: bold;
        text-align: center;
        color: white; }

#new-trip-container .progress-bar-container {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 50px; }
  #new-trip-container .progress-bar-container .progress {
    position: relative;
    background-color: black;
    width: 340px;
    height: 35px;
    border-radius: 50px; }
    #new-trip-container .progress-bar-container .progress .progress-bar {
      border-radius: inherit; }
  #new-trip-container .progress-bar-container .progress-status {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: absolute;
    top: auto;
    right: 17px;
    bottom: auto;
    left: auto;
    color: white;
    font-size: 1rem; }

@media (max-width: 320px) {
  #new-trip-container .step-container {
    padding: 20px; } }

@media (min-width: 576px) {
  #new-trip-container .step-container {
    padding: 30px 115px; }
    #new-trip-container .step-container [class*='-box'] span {
      font-size: 18px; } }

@media (min-width: 992px) {
  #new-trip-container .step-container {
    padding: 40px 60px; }
    #new-trip-container .step-container .step-title-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: nowrap;
      width: 40%; }
      #new-trip-container .step-container .step-title-container .step-title {
        padding-right: 20px; }
        #new-trip-container .step-container .step-title-container .step-title h2 {
          font-size: 24px;
          line-height: 29px; }
      #new-trip-container .step-container .step-title-container .step-number {
        margin-bottom: 10px; }
        #new-trip-container .step-container .step-title-container .step-number span {
          width: 40px;
          height: 40px;
          font-size: 24px; }
    #new-trip-container .step-container .options-container {
      justify-content: space-between;
      border-left: solid;
      padding-left: 50px;
      width: 60%; } }

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 30px; }
  .pagination > span {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: normal;
    flex-wrap: nowrap;
    color: #ea0029;
    font-size: 26px;
    cursor: pointer; }
  .pagination .pagination-numbers-container {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: normal;
    flex-wrap: nowrap;
    padding: 0 10px; }
    .pagination .pagination-numbers-container .pagination-number:not(:last-child) {
      margin-right: 15px; }
  .pagination .pagination-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 30px;
    height: 30px;
    background: #ea0029;
    border-radius: 100%;
    border: 2px solid #ea0029;
    color: white;
    cursor: pointer; }
    .pagination .pagination-number.active {
      background: white; }
      .pagination .pagination-number.active span {
        color: black; }
    .pagination .pagination-number span {
      font-weight: 600;
      font-size: 11px;
      color: white; }
  @media (min-width: 992px) {
    .pagination .arrow-right {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto; }
    .pagination .arrow-left {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0; } }

#activity-loader-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #ea0029; }

#suggestion-card-container {
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  min-height: 230px;
  min-width: 140px;
  max-width: 150px;
  margin-right: 10px;
  cursor: pointer; }
  #suggestion-card-container.utility-layout {
    position: relative;
    display: flex;
    max-width: unset;
    max-height: unset;
    min-height: 135px;
    margin-bottom: 20px;
    margin-right: 0; }
    #suggestion-card-container.utility-layout .suggestion-img {
      height: auto;
      width: 60%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
      #suggestion-card-container.utility-layout .suggestion-img .suggestion-add-icon {
        position: absolute;
        left: auto;
        top: auto;
        right: 15px;
        bottom: 15px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #ea0029;
        border-radius: 100%;
        background-color: rgba(234, 0, 41, 0.3); }
    #suggestion-card-container.utility-layout .suggestion-info {
      padding-left: 25px;
      width: 40%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; }
      #suggestion-card-container.utility-layout .suggestion-info .suggestion-title {
        text-align: left; }
    #suggestion-card-container.utility-layout .see-more-button svg {
      margin-left: 5px; }
  #suggestion-card-container .suggestion-img {
    width: 100%;
    height: 130px;
    background-size: cover;
    padding: 10px; }
    #suggestion-card-container .suggestion-img .suggestion-add-icon {
      border: 1px solid red;
      border-radius: 50%;
      width: 20%;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      color: #ea0029; }
  #suggestion-card-container .suggestion-info {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    padding: 15px; }
    #suggestion-card-container .suggestion-info .suggestion-title {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      white-space: normal;
      overflow: hidden; }
    #suggestion-card-container .suggestion-info .suggestion-hour {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      margin-top: 5px;
      color: #000000;
      text-align: left; }

#suggested-activities-list-container .featured-heading {
  text-align: left;
  margin-bottom: 25px; }

#activity-tile-container {
  display: flex;
  justify-content: space-between;
  background: #f2efef;
  padding: 15px 28px;
  min-height: 70px;
  cursor: pointer; }
  #activity-tile-container.utility-layout {
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    min-height: 95px;
    padding: 0;
    padding-right: 15px; }
    #activity-tile-container.utility-layout:not(:last-child) {
      margin-bottom: 15px; }
    #activity-tile-container.utility-layout .activity-img {
      width: 40%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    #activity-tile-container.utility-layout .activity-details-preview {
      padding: 10px 0 0 32px;
      text-align: left; }
  #activity-tile-container:not(:last-child) {
    margin-bottom: 6px; }
  #activity-tile-container .activity-details-preview {
    width: 100%;
    padding-right: 10px; }
  #activity-tile-container .activity-details-icon {
    width: 20px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    color: #b0b0b0;
    font-size: xx-large;
    cursor: pointer; }
  #activity-tile-container .activity-details-icon:hover {
    color: #ea0029; }
  #activity-tile-container .activity-name {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 5px;
    color: #000000; }
  #activity-tile-container .activity-time {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000; }
  @media (min-width: 576px) {
    #activity-tile-container {
      border-radius: 10px; } }

#selected-activities-list-container .activities-container {
  margin-bottom: 25px; }
  #selected-activities-list-container .activities-container ul {
    padding: 0; }

#selected-activities-list-container .builder-heading {
  display: flex;
  flex-direction: column; }
  #selected-activities-list-container .builder-heading .builder-title {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  #selected-activities-list-container .builder-heading .builder-actions {
    display: flex; }
    #selected-activities-list-container .builder-heading .builder-actions > div {
      margin-right: 22px;
      cursor: pointer; }
    #selected-activities-list-container .builder-heading .builder-actions svg {
      margin-right: 12px; }
    #selected-activities-list-container .builder-heading .builder-actions span {
      font-weight: 600; }
  #selected-activities-list-container .builder-heading .builder-info {
    margin-top: 10px;
    text-align: left; }
  #selected-activities-list-container .builder-heading .builder-time p {
    font-size: 10px; }
  #selected-activities-list-container .builder-heading .builder-empty {
    font-size: 20px; }

#selected-activities-list-container .see-more-button {
  font-size: 14px;
  text-align: right; }
  #selected-activities-list-container .see-more-button svg {
    margin-left: 5px; }

#activity-slider-container {
  width: 100%;
  height: 100vh;
  display: flex; }

.img-container {
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  background-position: center;
  padding: 30px; }
  .img-container .controls {
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between; }
  .img-container .slider-left {
    font-size: 50px;
    color: white;
    font-size: 20px;
    cursor: pointer; }
  .img-container .slider-right {
    font-size: 50px;
    color: white;
    font-size: 20px;
    cursor: pointer; }
  .img-container .circle-controler {
    width: 100%;
    height: 20px;
    display: flex;
    position: absolute;
    bottom: 5px;
    justify-content: center;
    align-items: center; }
    .img-container .circle-controler .circle-index {
      width: 15px;
      height: 15px;
      background: rgba(255, 255, 255, 0.49);
      border-radius: 50%;
      margin-right: 10px;
      cursor: pointer; }
    .img-container .circle-controler .circle-index-selected {
      background: #ffffff; }
  @media (max-width: 576px) {
    .img-container {
      padding: 15px; }
      .img-container .circle-controler .circle-index {
        width: 7px;
        height: 7px; } }

.close-menu {
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 0px;
  cursor: pointer;
  z-index: 99; }

@charset "UTF-8";
#activity-description-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100vh;
  overflow: auto;
  font-size: 18px; }
  #activity-description-container .activity-slider-container {
    width: 50%;
    height: 600px; }
  #activity-description-container .activity-description-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 50%;
    height: 100%;
    padding: 25px 50px;
    overflow-y: auto;
    overflow-x: hidden; }
    #activity-description-container .activity-description-content .content {
      width: 545px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
  #activity-description-container .activity-title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 37px;
    width: 100%;
    color: #000000;
    text-align: left;
    margin-bottom: 10px; }
  #activity-description-container .activity-time {
    display: flex;
    text-align: left;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: flex-start;
    margin-bottom: 30px; }
    #activity-description-container .activity-time .kid-frinedly-container {
      display: flex; }
      #activity-description-container .activity-time .kid-frinedly-container div {
        display: flex; }
    #activity-description-container .activity-time .kid-frinedly-container:before {
      content: '• ';
      font-size: 20px;
      margin-left: 10px;
      margin-right: 10px; }
    #activity-description-container .activity-time p {
      margin-left: 5px;
      margin-right: 5px; }
    #activity-description-container .activity-time img {
      width: 19px;
      height: 18px; }
  #activity-description-container .activity-author-desktop {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    margin-bottom: 25px; }
  #activity-description-container .activity-author-mobile {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 10px;
    display: none; }
  #activity-description-container .activity-description-title {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 20px; }
  #activity-description-container .activity-description {
    text-align: justify;
    width: 450px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    text-align: left;
    margin-bottom: 35px; }
    #activity-description-container .activity-description ul {
      padding: 10px 20px; }
      #activity-description-container .activity-description ul:last-child {
        padding-bottom: 0; }
    #activity-description-container .activity-description .description-see-more {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      justify-content: flex-end;
      color: #ea0029;
      cursor: pointer; }
      #activity-description-container .activity-description .description-see-more:hover {
        font-weight: bold; }
    #activity-description-container .activity-description .arrow-see-more {
      width: 9px;
      height: 7px;
      margin-top: 4px;
      margin-left: 4px; }
  @media (min-width: 576px) {
    #activity-description-container .activity-time {
      font-size: 13px; } }
  @media (max-width: 992px) {
    #activity-description-container .activity-slider-container {
      width: 100%;
      height: 275px; }
      #activity-description-container .activity-slider-container .img-container {
        width: 100%;
        height: 275px; }
      #activity-description-container .activity-slider-container .slider-left {
        display: none; }
      #activity-description-container .activity-slider-container .slider-right {
        display: none; }
      #activity-description-container .activity-slider-container .circle-controler {
        width: 100%;
        bottom: 0px; }
    #activity-description-container .activity-description-content {
      width: 100%;
      font-size: 12px;
      padding: 40px 35px;
      height: auto; }
      #activity-description-container .activity-description-content .content {
        width: 100%; }
    #activity-description-container .activity-author-desktop {
      display: none; }
    #activity-description-container .activity-author-mobile {
      display: inline; } }
  #activity-description-container .add-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    width: 50%;
    margin-top: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    background: #ffffff;
    height: 52px;
    padding: 10px;
    right: 0;
    bottom: 0; }
    #activity-description-container .add-btn span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: normal;
      flex-wrap: nowrap;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ea0029;
      text-align: right; }
    #activity-description-container .add-btn .suggestion-add-icon {
      border: 1px solid #ea0029;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-color: rgba(234, 0, 41, 0.3);
      cursor: pointer;
      color: #ea0029;
      margin-left: 10px;
      font-size: 15px; }
  @media (max-width: 992px) {
    #activity-description-container .add-btn {
      width: 100%; }
    #activity-description-container .activity-description-title {
      font-size: 20px;
      margin-bottom: 5px; }
    #activity-description-container .activity-title {
      font-size: 30px; } }

#activity-filters-container {
  display: flex;
  justify-content: center;
  margin: 20px 0; }
  #activity-filters-container select {
    padding-left: 20px; }
  #activity-filters-container .activities-filter {
    height: 65px;
    min-width: 190px;
    border-radius: 10px;
    background-color: #c4c4c4;
    font-size: 20px;
    border: none; }
    #activity-filters-container .activities-filter:not(:last-child) {
      margin-right: 25px; }
    #activity-filters-container .activities-filter.selected {
      background-color: #ea0029;
      color: white; }
  #activity-filters-container button {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    #activity-filters-container button span {
      font-size: 10px; }

#interests-columns-container {
  padding: 30px;
  min-height: 600px;
  background-size: cover; }
  #interests-columns-container .interests-columns-header {
    text-align: center;
    margin-bottom: 30px; }
  #interests-columns-container .interests-columns-title {
    font-weight: 800;
    font-size: 40px;
    color: #ea0029; }
  #interests-columns-container .interests-column-intro {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    max-width: 680px;
    margin: 0 auto; }
  #interests-columns-container .interests-columns {
    display: flex;
    height: 100%; }
    #interests-columns-container .interests-columns ul {
      padding-left: 0; }
    #interests-columns-container .interests-columns .interests-column {
      display: flex;
      flex-direction: column;
      width: 25%;
      padding: 30px 25px 0px;
      background-color: rgba(242, 242, 242, 0.9);
      border-radius: 10px; }
      #interests-columns-container .interests-columns .interests-column:not(:last-child) {
        margin-right: 20px; }
      #interests-columns-container .interests-columns .interests-column img {
        width: 60px;
        align-self: center; }
    #interests-columns-container .interests-columns .category-title {
      text-align: center;
      font-weight: bold;
      margin: 15px 0 30px 0; }
    #interests-columns-container .interests-columns .interest-container {
      margin-bottom: 20px;
      display: flex;
      align-items: center; }
      #interests-columns-container .interests-columns .interest-container label {
        margin-bottom: 0;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px; }
      #interests-columns-container .interests-columns .interest-container input {
        width: 15px;
        height: 15px; }

.notification-container {
  border: 2px solid #ea0029;
  border-radius: 5px;
  background-color: white;
  position: fixed;
  padding: 10px;
  width: 250px; }
  .notification-container .notification-message {
    font-size: 18px;
    text-align: center;
    color: #ea0029; }

.top-center {
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  -webkit-animation: toast-in-Top 0.7s;
          animation: toast-in-Top 0.7s; }

.bottom-center {
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  -webkit-animation: toast-in-Top 0.7s;
          animation: toast-in-Top 0.7s; }

.top-right {
  top: 12px;
  right: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  -webkit-animation: toast-in-right 0.7s;
          animation: toast-in-right 0.7s; }

.bottom-right {
  bottom: 12px;
  right: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  -webkit-animation: toast-in-right 0.7s;
          animation: toast-in-right 0.7s; }

.top-left {
  top: 12px;
  left: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in;
  transition: -webkit-transform 0.6s ease-in;
  transition: transform 0.6s ease-in;
  transition: transform 0.6s ease-in, -webkit-transform 0.6s ease-in;
  -webkit-animation: toast-in-left 0.7s;
          animation: toast-in-left 0.7s; }

.bottom-left {
  bottom: 12px;
  left: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in;
  transition: -webkit-transform 0.6s ease-in;
  transition: transform 0.6s ease-in;
  transition: transform 0.6s ease-in, -webkit-transform 0.6s ease-in;
  -webkit-animation: toast-in-left 0.7s;
          animation: toast-in-left 0.7s; }

@-webkit-keyframes toast-in-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes toast-in-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes toast-in-left {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes toast-in-left {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes toast-in-Top {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes toast-in-Top {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.print-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap; }
  .print-actions-container .print-title {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ea0029;
    font-weight: 600; }
  .print-actions-container .print-action-controls {
    display: flex; }
  .print-actions-container .send-itinrerary-text {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 442px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #c4c4c4; }
  .print-actions-container input {
    margin-bottom: 10px; }

.share-link-container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 586px;
  height: 650px; }
  .share-link-container .title-share-link {
    font-weight: bold;
    padding: 10px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ea0029;
    margin-top: 20%; }
  .share-link-container .subtitle-share-link {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: black;
    margin: 10px 0; }
  .share-link-container .share-controls {
    display: flex; }
  .share-link-container .share-alert {
    font-weight: bold;
    font-size: 18px;
    color: #ea0029;
    text-align: center; }
  .share-link-container .share-input {
    height: 53px;
    width: 491px;
    background-color: #f1f1f1;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ea0029;
    border-radius: 10px 0px 0px 10px;
    border: none;
    padding: 15px 20px; }

#auth-container {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 30px;
  margin: 25px auto 35px auto;
  width: 453px; }
  #auth-container .text-icon {
    position: absolute;
    z-index: 5;
    height: 26px; }
  #auth-container .auth-provider-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border: none;
    padding: 15px;
    margin-bottom: 10px;
    color: black;
    width: 100%;
    background: -webkit-linear-gradient(98.52deg, #ffffff 0%, #eeeeee 100%);
    background: linear-gradient(-8.52deg, #ffffff 0%, #eeeeee 100%);
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.07);
    border-radius: 5px; }
  #auth-container .auth-title {
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    text-align: center;
    color: #ea0029;
    margin-bottom: 25px;
    margin-top: 10px; }
  #auth-container .icon-btn {
    width: 38px;
    height: 26px; }
  #auth-container .social-media-sing-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; }
    #auth-container .social-media-sing-up button {
      margin-bottom: 10px; }
  #auth-container .auth-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 18px 0;
    width: 100%; }
  #auth-container .auth-divider-line {
    width: 42%; }
  #auth-container .auth-divider-o {
    font-family: 'Open Sans';
    font-weight: normal; }
  #auth-container .auth-fields {
    margin: 4px 0 30px 0; }
    #auth-container .auth-fields input[type='text'] {
      padding-left: 50px; }
    #auth-container .auth-fields input[type='password'] {
      padding-left: 50px; }
    #auth-container .auth-fields .input-group:not(:first-child) {
      margin-top: 15px; }
  #auth-container .auth-actions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap; }
  #auth-container .other-actions-link {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #8c8686; }
    #auth-container .other-actions-link u {
      cursor: pointer;
      color: #000000;
      margin-left: 3px; }
  @media (min-width: 576px) {
    #auth-container {
      padding: 0; } }

.nav.nav-tabs {
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 0 50px; }

.nav .nav-item {
  cursor: pointer;
  font-weight: bold; }
  .nav .nav-item a {
    font-size: 18px;
    line-height: 22px; }
  .nav .nav-item:not(:first-child) {
    margin-left: 15px; }
  .nav .nav-item:hover {
    color: #ea0029; }
  .nav .nav-item .nav-link {
    border-radius: 10px 10px 0px 0px;
    margin-top: 18px;
    height: 41px;
    color: #c4c4c4; }
  .nav .nav-item .active-tab {
    color: white;
    background: -webkit-linear-gradient(164.06deg, #ea0129 30.64%, #ee315f 81.08%);
    background: linear-gradient(285.94deg, #ea0129 30.64%, #ee315f 81.08%);
    border-radius: 10px 10px 0px 0px; }
    .nav .nav-item .active-tab:hover {
      color: white; }

#ambassador-short-info-container {
  margin: 15px 0;
  text-align: center; }
  #ambassador-short-info-container .user-photo {
    margin-bottom: 15px; }
  #ambassador-short-info-container span {
    color: #ea0029;
    font-size: 24px;
    font-weight: bold; }

@media (min-width: 992px) {
  .modal .custom-modal {
    width: 100%; } }

@media (min-width: 768px) {
  .modal .custom-modal modal-dialog {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0; } }

@media (min-width: 768px) {
  .modal .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    height: auto;
    min-height: 100%;
    border-radius: 0; } }

@media (min-width: 576px) {
  .modal .custom-modal {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    height: auto;
    max-width: 100%;
    border-radius: 0; } }

.close-container {
  width: 100%;
  margin: 22px 0px 0px -22px;
  display: flex;
  justify-content: flex-end; }

.header {
  background: #fff; }
  .header--nav {
    background: #fff;
    margin: 0;
    text-align: center;
    overflow: auto;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    bottom: -100%;
    left: 0;
    -webkit-transition: 0.5s ease-out;
    transition: 0.5s ease-out; }
    .header--nav a {
      display: block; }

.active .header--nav {
  bottom: -0%; }

.open-menu {
  margin-right: 1em; }

.close-menu {
  margin-bottom: 1em; }

#itinerary-utility-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px; }
  #itinerary-utility-container .sub-heading {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto; }
  #itinerary-utility-container .section-container {
    box-shadow: none; }
    #itinerary-utility-container .section-container .left-side {
      border-color: black;
      border-width: 1px; }

#redknot-verified-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap; }
  #redknot-verified-badge span {
    font-size: 11px;
    font-weight: normal;
    margin-left: 5px;
    line-height: 20px; }

.user-info-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap;
  width: 50%;
  padding: 0 6px;
  color: #929292;
  line-height: 20px; }
  .user-info-box:not(:last-child) {
    border-right: 0.5px solid #dddddd; }
  .user-info-box span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: normal;
    flex-wrap: nowrap;
    font-size: 8.5px;
    font-weight: normal;
    line-height: 11px; }
    .user-info-box span img {
      margin-right: 5px; }

.interests-box-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: normal;
  flex-wrap: nowrap;
  align-self: flex-start;
  text-align: left;
  width: 100%;
  border: 1px solid #ea0029;
  border-radius: 10px;
  border-top-left-radius: 0;
  padding: 7px 10px;
  margin-bottom: 10px;
  height: auto;
  max-height: 70px; }
  .interests-box-container > span {
    font-weight: 600;
    font-size: 10px;
    color: #ea0029; }
  .interests-box-container p {
    font-size: 9px;
    line-height: 11px;
    margin: 0; }

.card-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap;
  position: absolute;
  top: -30px;
  right: auto;
  bottom: auto;
  left: 0;
  color: #ffe600;
  background-color: black;
  padding: 5px 15px;
  padding-bottom: 50px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: -1; }
  .card-badge svg {
    font-size: 20px;
    margin-right: 5px; }
  .card-badge div {
    font-size: 9px;
    font-weight: bold; }

.total-price {
  font-weight: bold;
  text-align: left;
  color: #737373;
  line-height: normal;
  line-height: initial; }
  .total-price .per-person-price {
    font-weight: 500;
    line-height: 22px;
    font-size: 18px;
    color: black;
    text-align: left; }

.ambassador-result-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  position: relative;
  margin-top: 60px;
  padding: 20px;
  width: 100%;
  height: calc(95vh - 80px);
  max-height: 550px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 25px; }
  .ambassador-result-card button {
    font-size: 11px;
    min-width: 100px;
    line-height: 11px; }
  .ambassador-result-card .card-photo-container {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: auto;
    width: 100%;
    height: 48%;
    cursor: pointer; }
    .ambassador-result-card .card-photo-container .photo-profile {
      width: 100%;
      height: 100%;
      background-color: white;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px; }
  .ambassador-result-card .card-info-body {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: calc(50% - 90px); }
  .ambassador-result-card .card-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    font-size: 10px; }
    .ambassador-result-card .card-info .card-headline {
      display: flex;
      flex-direction: row;
      align-items: normal;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%; }
      .ambassador-result-card .card-info .card-headline > span {
        font-size: 18px;
        font-weight: bold; }
    .ambassador-result-card .card-info .card-data {
      display: flex;
      flex-direction: row;
      align-items: normal;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
      border-left: 0.5px solid #dddddd;
      border-right: 0.5px solid #dddddd;
      margin-bottom: 10px; }
  .ambassador-result-card .card-info-footer {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-top: 0.5px solid #dddddd;
    padding-top: 15px; }
    .ambassador-result-card .card-info-footer .pricing {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: normal;
      flex-wrap: wrap;
      font-size: 9px;
      margin-bottom: 10px; }
  @media screen and (min-width: 576px) {
    .ambassador-result-card {
      width: 275px; } }

p.explanatory-text {
  margin-top: 15px; }
  p.explanatory-text a {
    color: #ea0029;
    font-weight: bold;
    text-decoration: underline;
    font-size: 16px; }
    p.explanatory-text a:hover {
      color: #ea0029; }

#result-list-container .result-grid {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 30px; }
  #result-list-container .result-grid h4 {
    width: 100%;
    font-size: 1em; }

.modal-body {
  white-space: pre-line;
  text-align: justify; }

#share-buttons {
  margin-top: 20px;
  color: #ea0029; }
  #share-buttons .share-text {
    margin-bottom: 10px;
    font-weight: bold; }
  #share-buttons button {
    cursor: pointer; }
  #share-buttons button:not(:last-child) {
    margin-right: 10px; }

.data-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap;
  color: #929292;
  font-size: 13px;
  line-height: 23px;
  cursor: default;
  text-align: left; }
  .data-span svg {
    min-width: 25px;
    color: #ea0029;
    margin-right: 10px; }
  @media (max-width: 992px) {
    .data-span {
      font-size: 11px; } }
  @media (max-width: 500px) {
    .data-span {
      line-height: 20px; } }

.day-activities-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  border-top: 1px solid #dddddd; }
  .day-activities-container .day-activities-title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-top: 50px;
    color: #ea1c2d;
    width: 100%; }
    .day-activities-container .day-activities-title div {
      margin-top: 10px;
      color: #929292; }
    @media (max-width: 500px) {
      .day-activities-container .day-activities-title {
        font-size: 18px; }
        .day-activities-container .day-activities-title div {
          font-size: 1em; } }
  .day-activities-container .timeline-container {
    max-width: 1400px;
    margin: 30px auto;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 30px; }
    .day-activities-container .timeline-container .timeline {
      list-style: none;
      padding: 0;
      position: relative;
      display: flex;
      flex-direction: column; }
      @media (min-width: 1200px) {
        .day-activities-container .timeline-container .timeline {
          flex-direction: row; } }
      .day-activities-container .timeline-container .timeline:before {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        content: '';
        margin-left: 100px;
        width: 2px;
        height: 100%;
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(8%, black), color-stop(8%, black), to(black));
        background-image: -webkit-linear-gradient(top, black 8%, black 8%, black 100%);
        background-image: linear-gradient(to bottom, black 8%, black 8%, black 100%);
        -webkit-transform: translateX(calc(50% + 30px / 2));
                transform: translateX(calc(50% + 30px / 2)); }
        @media (max-width: 1200px) {
          .day-activities-container .timeline-container .timeline:before {
            margin-top: 35px;
            margin-left: 0px;
            height: 81%; } }
        @media (max-width: 500px) {
          .day-activities-container .timeline-container .timeline:before {
            height: 79%; } }
        @media (min-width: 1200px) {
          .day-activities-container .timeline-container .timeline:before {
            width: 80%;
            height: 2px;
            border-radius: 4px;
            background-image: -webkit-gradient(linear, left top, right top, color-stop(8%, black), color-stop(8%, black), to(black));
            background-image: -webkit-linear-gradient(left, black 8%, black 8%, black 100%);
            background-image: linear-gradient(to right, black 8%, black 8%, black 100%);
            -webkit-transform: translateY(calc(50% + 30px / 2));
                    transform: translateY(calc(50% + 30px / 2)); } }
      .day-activities-container .timeline-container .timeline__step {
        display: flex;
        align-items: center;
        flex: 1 1;
        text-align: center;
        position: relative;
        flex-direction: row;
        padding: 30px 0;
        margin-top: 5px; }
        @media (min-width: 1200px) {
          .day-activities-container .timeline-container .timeline__step {
            flex-direction: column;
            padding: 0 30px; } }
        @media (max-width: 1200px) {
          .day-activities-container .timeline-container .timeline__step {
            align-items: baseline; } }
        @media (max-width: 500px) {
          .day-activities-container .timeline-container .timeline__step {
            padding: 10px 0; } }
        .day-activities-container .timeline-container .timeline__step:before {
          content: '';
          min-width: 30px;
          height: 30px;
          border-radius: 40px;
          border: solid 6px #dc3545;
          background-color: #dc3545;
          margin: 0 30px 0 0; }
          @media (min-width: 1200px) {
            .day-activities-container .timeline-container .timeline__step:before {
              margin: 0 0 30px 0; } }
          @media (max-width: 500px) {
            .day-activities-container .timeline-container .timeline__step:before {
              min-width: 20px;
              height: 20px;
              margin-left: 7px; } }
      .day-activities-container .timeline-container .timeline__title {
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #737373;
        line-height: 15px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
        @media (max-width: 1199px) {
          .day-activities-container .timeline-container .timeline__title {
            text-align: left; } }
        @media (max-width: 500px) {
          .day-activities-container .timeline-container .timeline__title {
            text-align: left;
            text-align: initial; } }
    .day-activities-container .timeline-container .timeline-hour {
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #ea1c2d;
      font-weight: bold; }
      @media (max-width: 1200px) {
        .day-activities-container .timeline-container .timeline-hour {
          text-align: left;
          text-align: initial; } }

#group-controls {
  display: flex;
  justify-content: center; }
  #group-controls .group-controles-user-profile {
    padding: 15px;
    top: -232px;
    position: absolute;
    width: 240px;
    height: 257px;
    display: flex;
    background: #f1f1f1;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px; }

#booking-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 166px;
  left: 0px;
  position: fixed;
  bottom: 0px;
  background-color: white;
  box-shadow: 1px 0px 11px lightgrey; }
  #booking-bar-container #pricing {
    width: 329px; }
  #booking-bar-container .btn-container {
    width: 954px;
    display: flex;
    justify-content: space-around; }
  #booking-bar-container .total-price span {
    color: #ea0029;
    font-size: 48px; }
  #booking-bar-container #dates-booking-buttons {
    justify-content: inherit;
    align-items: center; }
    #booking-bar-container #dates-booking-buttons input {
      display: none; }
    #booking-bar-container #dates-booking-buttons > span {
      width: 250px;
      color: #dc3545;
      font-weight: bold;
      margin-top: 5px;
      cursor: pointer; }
  #booking-bar-container .button-primary {
    width: 100%; }
  #booking-bar-container .alerts-container {
    width: 100%; }
    #booking-bar-container .alerts-container .alert {
      margin: 0;
      margin-top: 5px; }
  @media (min-width: 576px) {
    #booking-bar-container .button-primary {
      width: 250px; } }
  @media (min-width: 768px) {
    #booking-bar-container {
      justify-content: space-between;
      padding: 35px 30px; } }

.about-box-container {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: normal;
  flex-wrap: nowrap;
  margin-top: 60px;
  width: 400px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 10px; }
  .about-box-container .about-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: -45px; }
    .about-box-container .about-icon-container img {
      width: 75px;
      height: 75px;
      background-color: white;
      border-radius: 100%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      padding: 10px; }
  .about-box-container .about-data-container {
    font-weight: normal;
    font-weight: initial;
    color: #737373;
    text-align: justify 12px;
    font-size: 1rem;
    text-decoration: none;
    padding: 22px 42px; }
    .about-box-container .about-data-container h5 {
      font-weight: bold;
      color: black;
      text-align: center;
      font-size: 18px;
      text-decoration: none; }
    .about-box-container .about-data-container .read-more-button {
      background: none;
      border: none;
      padding: 0;
      font-family: arial, sans-serif;
      color: #ea0029;
      text-decoration: underline; }
      .about-box-container .about-data-container .read-more-button:not(:disabled):active {
        color: #ea0029;
        background: none;
        border: none; }
      .about-box-container .about-data-container .read-more-button:not(:disabled):active:focus {
        box-shadow: unset; }
  @media (min-width: 1200px) {
    .about-box-container {
      width: 25%; } }

.vehicle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  height: 85px;
  max-width: 400px;
  min-height: 65px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: #f4f4f4; }
  .vehicle-container .vehicle-box-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px; }
  .vehicle-container .vehicle-details {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
    flex-wrap: nowrap;
    font-size: 12px; }
  .vehicle-container .vehicle-image-container {
    padding: 0 10px; }
  @media (min-width: 768px) and (max-width: 1200px) {
    .vehicle-container {
      max-width: 400px; } }
  @media (min-width: 992px) {
    .vehicle-container {
      margin-top: 0; } }

.interviewed-by-container {
  margin-top: 10px;
  font-size: 11px; }
  .interviewed-by-container a {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: center;
    flex-wrap: nowrap; }
  .interviewed-by-container .user-photo {
    margin-left: 10px; }

.carousel.carousel-slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  margin-top: -30px;
  height: 400px;
  cursor: pointer;
  background: black; }
  .carousel.carousel-slider .carousel-image-container {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px; }
  @media (max-width: 576px) {
    .carousel.carousel-slider .slide {
      margin: 1px !important;
      opacity: 0.5; }
      .carousel.carousel-slider .slide.selected {
        opacity: 1; }
      .carousel.carousel-slider .slide .carousel-image-container {
        background-size: cover; } }
  @media (min-width: 576px) {
    .carousel.carousel-slider {
      margin-top: -30px; } }

.interest-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 10px; }
  .interest-bar svg {
    width: 25px !important;
    color: #ea0029;
    margin-right: 7px; }
  .interest-bar .progress {
    width: 100%;
    height: 12px;
    border-radius: 10px;
    background-color: black; }

.interests-bars-container {
  width: 100%;
  max-width: 400px; }
  .interests-bars-container .interests-row {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-between;
    flex-wrap: wrap; }
    .interests-bars-container .interests-row .main-interests-column {
      display: grid;
      justify-items: center;
      width: calc(50% - 10px); }
    .interests-bars-container .interests-row .secondary-interests-column {
      display: grid;
      justify-items: center;
      width: calc(50% - 10px); }
  .interests-bars-container .main-title {
    font-weight: 900;
    font-size: 18px; }

.user-profile-container {
  padding-bottom: 50px;
  margin-bottom: 250px; }
  .user-profile-container .user-header-row {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 370px; }
  .user-profile-container .user-core-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%; }
    .user-profile-container .user-core-column .user-core-data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: normal;
      flex-wrap: nowrap;
      margin: 15px; }
    .user-profile-container .user-core-column .user-name {
      display: flex;
      flex-direction: row;
      align-items: normal;
      justify-content: flex-start;
      flex-wrap: nowrap;
      font-weight: 900;
      font-size: 18px;
      margin-bottom: 10px; }
    .user-profile-container .user-core-column .info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      margin-top: 5px;
      padding-left: 10px;
      border-left: solid 0.5px lightgray; }
  .user-profile-container .top-interests-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin: 15px 0;
    width: 100%; }
  .user-profile-container .other-info-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 15px; }
  .user-profile-container .user-about-container {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;
    border-top: 1px solid #dddddd; }
  @media (max-width: 320px) {
    .user-profile-container .user-core-column {
      flex-direction: column; } }
  @media (max-width: 576px) {
    .user-profile-container .user-header-row {
      margin-top: 415px; }
    .user-profile-container .user-core-column .user-name {
      justify-content: center; } }
  @media (min-width: 768px) {
    .user-profile-container .user-header-row {
      margin-top: 380px; }
    .user-profile-container .user-core-column {
      width: 50%; }
    .user-profile-container .top-interests-column {
      width: 50%; } }
  @media (min-width: 992px) {
    .user-profile-container {
      margin-bottom: 150px; }
      .user-profile-container .user-core-column {
        width: 34.3% !important; }
        .user-profile-container .user-core-column .user-name {
          margin-left: 10px; }
        .user-profile-container .user-core-column .info-container {
          border-left: none; }
        .user-profile-container .user-core-column .user-photo .user-image {
          width: 154px;
          height: 154px; }
      .user-profile-container .top-interests-column {
        width: 33.3% !important;
        padding-right: 10px; }
      .user-profile-container .other-info-column {
        width: 32.3% !important;
        margin-top: 35px;
        padding-left: 10px; } }

.tooltip {
  margin-bottom: 50px !important; }

.detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 20px 0; }
  .detail-item.bordered {
    border-bottom: 1px solid #f0f0f0; }
  .detail-item:last-child {
    border-bottom: none; }
  .detail-item label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0; }
  .detail-item span {
    font-size: 12px; }
  .detail-item input {
    width: 150px; }
    .detail-item input:focus {
      box-shadow: none;
      border-color: #ea0029; }
  .detail-item.price span {
    font-size: 18px;
    font-weight: 700; }
  .detail-item.price .per-person-container {
    font-size: 10px;
    font-weight: normal; }

#booking-container {
  margin-top: 30px; }
  #booking-container .ambassador-container {
    text-align: left; }
    #booking-container .ambassador-container .heading {
      font-size: 12px;
      font-weight: 600; }
    #booking-container .ambassador-container .ambassador-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: normal;
      flex-wrap: nowrap;
      margin: 10px 0;
      background-color: #f4f4f4;
      border-radius: 5px;
      padding: 12px; }
    #booking-container .ambassador-container .ambassador-profile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: normal;
      flex-wrap: nowrap;
      margin-left: 13px; }
      #booking-container .ambassador-container .ambassador-profile div:first-child {
        font-weight: 700;
        font-size: 12px; }
      #booking-container .ambassador-container .ambassador-profile div:last-child {
        font-weight: 500;
        color: #ea0029;
        text-align: left;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer; }
  #booking-container .details-container {
    margin-bottom: 20px; }
    #booking-container .details-container input[type='email'] {
      min-width: 237px; }
    #booking-container .details-container .dates-container {
      text-align: right; }
    #booking-container .details-container .promo-code {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap; }
      #booking-container .details-container .promo-code input {
        font-weight: 700; }
    #booking-container .details-container .group-size-inputs-container {
      display: flex;
      flex-direction: column;
      align-items: normal;
      justify-content: normal;
      flex-wrap: nowrap; }
  #booking-container .price-container {
    margin: 30px 0 7px 0; }
  #booking-container .total-price {
    text-align: right;
    color: black; }
    #booking-container .total-price span {
      font-size: 1.3rem; }
  #booking-container .valid-booking-actions button {
    margin-bottom: 5px; }
    #booking-container .valid-booking-actions button:active:focus {
      box-shadow: none; }
  #booking-container .valid-booking-actions a {
    text-decoration: none;
    color: #ea0029;
    font-weight: bold;
    font-size: 12px; }
    #booking-container .valid-booking-actions a:hover {
      color: #ea0029;
      text-decoration: none;
      font-size: 1rem; }
  #booking-container .valid-booking-actions .side-note {
    width: 100%;
    font-size: 12px; }

.leaflet-container {
  width: 100%;
  height: 200px; }

.leaflet-control-container .leaflet-routing-container-hide {
  display: none; }

.leaflet-control-container .leaflet-routing-alternatives-container {
  display: none; }

#leaflet-map .search-control-icon-button {
  padding: 0px;
  padding-left: 5px;
  margin: 0; }
  #leaflet-map .search-control-icon-button .search-control-input {
    font-family: 'Montserrat';
    background-color: white; }

.section-hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  background-size: cover;
  border-radius: 0px 0px 10px 10px;
  padding: 40px;
  color: white; }
  .section-hero .hero-title {
    font-size: 36px;
    font-weight: bold; }
  @media (max-width: 576px) {
    .section-hero.full-width {
      width: 100vw;
      margin-left: -20px; } }
  @media (min-width: 768px) {
    .section-hero .hero-title {
      max-width: 477px;
      font-size: 64px; } }

.custom-switch-container {
  width: 45px;
  margin-top: -33px; }
  .custom-switch-container .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden; }
  .custom-switch-container .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 45px;
    height: 20px;
    border: 0.7px solid #000000;
    border-radius: 100px;
    position: relative;
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s; }
  .custom-switch-container .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    width: 25px;
    height: 20px;
    border-radius: 45px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background: #ffffff;
    border: 0.7px solid #000000; }
  .custom-switch-container .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100%);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

#create-activity-container {
  padding: 30px;
  max-width: 900px;
  margin: 0 auto; }
  #create-activity-container .create-activity-header {
    margin: 20px; }
    #create-activity-container .create-activity-header .create-activity-title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 37px;
      text-align: center;
      margin-top: 20px; }
  #create-activity-container .half-container {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-between;
    flex-wrap: wrap; }
    #create-activity-container .half-container .half-input {
      width: 50%; }
  #create-activity-container form {
    line-height: 20px;
    margin-top: 30px; }
    #create-activity-container form .row {
      margin-bottom: 50px; }
    #create-activity-container form .optional {
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 13px;
      width: 100%;
      text-align: left;
      color: #929292;
      margin-top: -6px; }
    #create-activity-container form label {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      color: #000000;
      width: 100%;
      margin-top: 15px; }
    #create-activity-container form input {
      background: #f7f7f7;
      border-radius: 7px;
      border: none; }
    #create-activity-container form textArea {
      background: #f7f7f7;
      border-radius: 7px;
      width: 100%;
      border: #f7f7f7;
      padding: 15px; }
    #create-activity-container form textArea:focus {
      background: white;
      border-color: #80bdff;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 123, 255, 0.6);
      outline: 0 none; }
    #create-activity-container form button {
      border-radius: 10px;
      height: 40px;
      align-items: center;
      font-size: 14px;
      padding: 10px; }
    #create-activity-container form .form-error {
      text-align: left;
      color: red;
      font-weight: bold;
      width: 100%; }
    #create-activity-container form .upload-img {
      display: flex;
      justify-content: center;
      background: #c4c4c4;
      border-radius: 10px;
      width: 100%;
      height: 37px;
      padding: 10px;
      cursor: pointer;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin-top: 10px;
      color: #ffffff; }
      #create-activity-container form .upload-img div {
        margin-right: 10px; }
    #create-activity-container form .upload-img-active {
      background: #3f3f3f; }
    #create-activity-container form .gallery-preview-container {
      display: flex; }
      #create-activity-container form .gallery-preview-container img {
        width: 100px;
        height: 100px; }
      #create-activity-container form .gallery-preview-container svg:hover {
        cursor: pointer;
        color: #ea0029; }
    #create-activity-container form .switch-container {
      display: flex;
      justify-content: space-between;
      width: 76%;
      text-align: justify;
      flex-wrap: wrap; }
      #create-activity-container form .switch-container .switch-description {
        width: 213px;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: #929292; }
  #create-activity-container .field-array-controls {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: flex-end;
    flex-wrap: nowrap;
    color: #ea0029;
    cursor: pointer; }
    #create-activity-container .field-array-controls .container-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      border: 1px solid red;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
      color: #ea0029;
      margin-left: 10px; }
  #create-activity-container .field-array-container {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: normal;
    flex-wrap: wrap; }
    #create-activity-container .field-array-container input {
      width: 90%; }
    #create-activity-container .field-array-container .container-icon {
      align-self: center;
      width: 10%;
      cursor: pointer;
      color: #ea0029;
      font-size: 20px; }
  #create-activity-container input[type='time']::-webkit-datetime-edit-ampm-field {
    display: none; }
  #create-activity-container .custom-time-picker {
    display: table; }
    #create-activity-container .custom-time-picker .react-time-picker__wrapper {
      border: none;
      width: 16px; }
    #create-activity-container .custom-time-picker .react-time-picker__inputGroup {
      border-radius: 7px;
      padding: 1px 5px 16px;
      background: #f7f7f7; }
    #create-activity-container .custom-time-picker button {
      display: none; }
    #create-activity-container .custom-time-picker input {
      margin: 5px 0; }
  @media (max-width: 576px) {
    #create-activity-container form button {
      margin-top: 20px; }
    #create-activity-container .create-activity-header .create-activity-title {
      font-size: 30px; }
    #create-activity-container .switch-container .switch-description {
      width: 100% !important; } }

.time-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  margin-top: 30px; }
  .time-bar-container .bar-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: absolute;
    width: 100%; }
    .time-bar-container .bar-text span {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #ffffff; }
  .time-bar-container .progress {
    height: 24px;
    border-radius: 20px;
    border: 1px solid #50555c;
    background: white;
    width: 80%; }
    .time-bar-container .progress .progress-bar {
      background: #50555c;
      border-radius: 20px; }

#activities-container {
  width: 100vw;
  margin-left: -45px;
  max-height: 236px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px; }
  #activities-container ul {
    padding-left: 0; }
  #activities-container .button-primary {
    font-weight: bold; }
  @media (min-width: 576px) {
    #activities-container {
      width: 100%;
      margin-left: auto;
      margin-top: 30px; } }

:root {
  --animate-duration: 1.5s;
  --animate-delay: 1.5s;
  --animate-repeat: 1.5; }

.animate__animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: 1.5s;
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.animate__bounceIn {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(1.5s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(1.5s * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.animate__flipOutX {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
  -webkit-animation-duration: calc(1.5s * 0.75);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(1.5s * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

#itinerary-builder-container {
  margin-bottom: 20px; }
  #itinerary-builder-container .heading {
    text-align: left;
    font-weight: bold;
    font-size: 30px;
    color: #ea0029;
    margin-bottom: 20px; }
    #itinerary-builder-container .heading svg {
      font-size: 16px;
      color: #929292;
      cursor: pointer; }
  #itinerary-builder-container .edit-icon.active {
    color: #ea0029; }
  #itinerary-builder-container .download-invoice,
  #itinerary-builder-container .edit-icon {
    margin-left: 25px; }
  #itinerary-builder-container .sub-heading {
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #000000; }
  #itinerary-builder-container .save-itinerary {
    display: flex;
    margin-top: 20px; }
    #itinerary-builder-container .save-itinerary .sub-heading {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000; }
    #itinerary-builder-container .save-itinerary img {
      width: 11px;
      height: 16px;
      margin-right: 10px;
      margin-top: 5px;
      cursor: pointer; }

.itinerary-field {
  width: 40%;
  height: 100%; }
  .itinerary-field label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px; }
  .itinerary-field input {
    width: 100%;
    padding: 7px 11px;
    background-color: #f2efef;
    border-radius: 8px;
    text-align: left;
    border: none;
    font-size: 11px; }
  .itinerary-field textarea {
    background-color: #c4c4c4;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    padding: 11px; }
  .itinerary-field div {
    color: #ea0029;
    font-size: 12px; }
  @media (min-width: 576px) {
    .itinerary-field label {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px; }
    .itinerary-field input {
      font-size: 16px;
      padding: 10px 15px; } }

.itinerary-day {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: normal;
  flex-wrap: nowrap; }
  .itinerary-day button.itinerary-day-button {
    width: 65px;
    height: 65px;
    background-color: #c4c4c4;
    font-weight: normal;
    font-size: 12px;
    border-radius: 8px;
    border: none;
    padding: 7px; }
    .itinerary-day button.itinerary-day-button:focus {
      box-shadow: none !important; }
    .itinerary-day button.itinerary-day-button:active {
      background-color: black !important; }
      .itinerary-day button.itinerary-day-button:active .day-number {
        color: #ea0029; }
      .itinerary-day button.itinerary-day-button:active .day-date {
        color: white !important; }
    .itinerary-day button.itinerary-day-button .day-date {
      font-size: 11px;
      line-height: 13px;
      color: black; }
    .itinerary-day button.itinerary-day-button .day-number {
      color: black;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px; }
    .itinerary-day button.itinerary-day-button.active {
      background-color: black !important; }
      .itinerary-day button.itinerary-day-button.active .day-number {
        color: #ea0029; }
      .itinerary-day button.itinerary-day-button.active .day-date {
        color: white; }
  .itinerary-day .day-activities {
    position: absolute;
    top: 70px;
    right: auto;
    bottom: 132px;
    left: 0;
    display: none;
    margin-top: 45px;
    width: 100%;
    height: 50%; }
  .itinerary-day.selected .day-activities {
    display: block; }
  @media (min-width: 576px) {
    .itinerary-day button.itinerary-day-button {
      width: 105px;
      height: 75px;
      padding: 7px 20px; }
      .itinerary-day button.itinerary-day-button:not(:last-child) {
        margin-right: 65px; } }
  @media (min-width: 992px) {
    .itinerary-day .day-activities {
      padding-left: 80px; } }

.chat-widget {
  cursor: pointer; }
  .chat-widget .widget-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 100%; }
  .chat-widget .widget-text {
    font-size: 11px;
    margin-top: 6px; }
  @media (min-width: 576px) {
    .chat-widget .widget-icon {
      width: 60px !important;
      height: 60px !important; } }

.itinerary-user-bar {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 25px; }
  .itinerary-user-bar .user-role {
    margin-top: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: left; }
  .itinerary-user-bar .user-contact {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: normal;
    flex-wrap: nowrap; }
    .itinerary-user-bar .user-contact .user-photo {
      margin-right: 18px; }
  @media (min-width: 576px) {
    .itinerary-user-bar {
      margin-bottom: 45px; }
      .itinerary-user-bar .user-role {
        font-size: 30px;
        line-height: 37px; }
      .itinerary-user-bar .user-contact .user-photo {
        margin-right: 30px; }
      .itinerary-user-bar .user-image {
        width: 60px !important;
        height: 60px !important; } }

#activity-suggestions-container {
  position: relative;
  width: 100%;
  margin-top: 28px; }
  #activity-suggestions-container .suggestion-title {
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #000000; }
  #activity-suggestions-container .suggestions-info-container {
    margin-top: 30px; }
  #activity-suggestions-container .suggestion-cards-container::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
  #activity-suggestions-container .suggestion-cards-container {
    display: flex;
    overflow: scroll;
    max-width: 100%;
    white-space: nowrap;
    margin-top: 30px;
    padding: 5px; }
    #activity-suggestions-container .suggestion-cards-container .suggestions-right-arrow {
      display: flex;
      align-items: center;
      position: absolute;
      height: 80%;
      top: 20%;
      right: -15px;
      background: -webkit-gradient(linear, right top, left top, color-stop(11.39%, #ffffff), color-stop(40.94%, #ffffff), color-stop(62.28%, rgba(255, 255, 255, 0.36)), color-stop(88.55%, rgba(255, 255, 255, 0)), color-stop(116.46%, rgba(255, 255, 255, 0)));
      background: -webkit-linear-gradient(right, #ffffff 11.39%, #ffffff 40.94%, rgba(255, 255, 255, 0.36) 62.28%, rgba(255, 255, 255, 0) 88.55%, rgba(255, 255, 255, 0) 116.46%);
      background: linear-gradient(270deg, #ffffff 11.39%, #ffffff 40.94%, rgba(255, 255, 255, 0.36) 62.28%, rgba(255, 255, 255, 0) 88.55%, rgba(255, 255, 255, 0) 116.46%);
      padding-left: 55px;
      color: #c4c4c4;
      cursor: pointer; }
      #activity-suggestions-container .suggestion-cards-container .suggestions-right-arrow svg {
        width: 13px;
        height: 25px; }
  @media (max-width: 576px) {
    #activity-suggestions-container {
      width: calc(100% + 45px); }
      #activity-suggestions-container .suggestion-cards-container {
        margin-top: 15px; }
        #activity-suggestions-container .suggestion-cards-container .suggestions-right-arrow {
          display: none; }
      #activity-suggestions-container .suggestion-title {
        font-size: 20px; } }

#itinerary-container {
  justify-content: center;
  padding-top: 30px; }
  #itinerary-container .hide-map {
    display: none; }
  #itinerary-container .hero-title {
    line-height: 29px; }
  #itinerary-container .section-side {
    position: relative;
    min-height: 450px; }
    #itinerary-container .section-side.right-side {
      margin-top: 25px;
      text-align: left; }
  #itinerary-container hr {
    width: calc(100% + 50px);
    margin-left: -25px; }
  #itinerary-container .itinerary-main-info {
    margin: 24px 0;
    text-align: left; }
    #itinerary-container .itinerary-main-info .itinerary-container {
      display: flex;
      flex-direction: row;
      align-items: normal;
      justify-content: space-around;
      flex-wrap: wrap; }
  #itinerary-container .primary-heading {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    color: #ea0029; }
  #itinerary-container .itinerary-days-container {
    display: flex;
    flex-direction: row;
    align-items: normal;
    justify-content: space-evenly;
    flex-wrap: nowrap; }
  @media (min-width: 576px) {
    #itinerary-container .section-hero {
      height: 300px !important; }
      #itinerary-container .section-hero .hero-title {
        font-size: 30px !important;
        line-height: 44px; }
    #itinerary-container hr {
      width: auto;
      margin-left: auto; }
    #itinerary-container .itinerary-main-info {
      margin: 40px 0; }
    #itinerary-container .itinerary-days-container {
      justify-content: flex-start; } }
  @media (min-width: 768px) {
    #itinerary-container hr {
      width: calc(100% + 50px);
      margin-left: -25px; }
    #itinerary-container .section-hero {
      padding: 70px 90px; } }
  @media (min-width: 992px) {
    #itinerary-container .section-side {
      width: 50%; }
      #itinerary-container .section-side.right-side {
        margin-top: 0;
        padding-left: 80px; } }
  @media (max-width: 576px) {
    #itinerary-container .section-container {
      box-shadow: none; }
    #itinerary-container .itinerary-container {
      justify-content: center; }
    #itinerary-container .suggestions-container .suggestion-title {
      font-size: 20px; }
    #itinerary-container #itinerary-builder-container .heading {
      text-align: center; }
    #itinerary-container #itinerary-builder-container .sub-heading {
      text-align: center; }
    #itinerary-container #itinerary-builder-container .save-itinerary {
      justify-content: center; } }

.trip-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 125px;
  padding: 20px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  cursor: pointer; }
  .trip-item:hover {
    text-decoration: none; }
  .trip-item:not(:last-child) {
    margin-bottom: 25px; }
  .trip-item .trip-details {
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
    flex-wrap: nowrap;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: white; }
  .trip-item .trip-dates {
    font-size: 11px;
    line-height: 13px;
    font-weight: normal;
    margin-top: 5px; }

#trips-container {
  margin-top: -5px; }

.trip-list-title {
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin-bottom: 35px; }

#chat-container .chat-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  max-width: 768px;
  height: 70px;
  background-color: #c4c4c4;
  color: black; }
  #chat-container .chat-navbar .chat-names {
    font-size: 18px;
    font-weight: bold; }

#chat-container .messages-container {
  margin: 50px 0 80px 0; }

#chat-container .message {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-bottom: 10px; }
  #chat-container .message .message-text {
    width: 100%;
    padding: 20px 30px;
    text-align: left;
    border-radius: 8px; }
  #chat-container .message.sent .message-text {
    margin-right: 15px;
    color: white;
    background-color: #949494;
    border-bottom-right-radius: 0; }
  #chat-container .message.received {
    flex-direction: row-reverse; }
    #chat-container .message.received .message-text {
      margin-left: 15px;
      color: black;
      background-color: #c4c4c4;
      border-bottom-left-radius: 0; }

#chat-container .input-fixer {
  position: fixed;
  bottom: 10px;
  width: 70%;
  max-width: 960px;
  margin: 0 auto; }

#chat-container .input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative; }

#chat-container .chat-input {
  height: 60px;
  width: 100%;
  padding: 20px;
  border: none;
  background-color: #f7f7f7;
  border-radius: 10px;
  color: #929292; }

#chat-container .button-chat {
  position: absolute;
  top: auto;
  right: 20px;
  bottom: auto;
  left: auto;
  width: 80px;
  height: 35px;
  color: #ea0029;
  border: none;
  border-radius: 7px;
  background-color: #b0b0b0b0;
  font-weight: 500; }

@media (min-width: 768px) {
  #chat-container .chat-navbar {
    left: auto;
    left: initial; } }

#not-found .header {
  font-size: 50px; }

#not-found .error-code {
  line-height: 1;
  font-weight: bold; }

#not-found .error-message {
  font-size: 30px; }

@media (max-width: 320px) {
  #not-found .error-message {
    font-size: 20px; }
  #not-found .error-code {
    font-size: 100px; }
  #not-found .header {
    font-size: 30px; } }

@media (min-width: 320px) {
  #not-found .error-code {
    font-size: 150px; } }

@media (min-width: 576px) {
  #not-found .error-code {
    font-size: 250px; } }

html {
  font-size: 14px; }

body {
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black !important;
  min-height: calc(100% - 56px) !important;
  top: 0; }

*:focus {
  outline: none !important; }

.App {
  text-align: center; }
  .App select {
    background-color: #f7f7f7;
    border: none; }
    .App select:focus {
      background-color: #f7f7f7;
      border: none; }
  .App a {
    color: #000;
    color: initial; }
    .App a:hover {
      color: #000;
      color: initial;
      text-decoration: none; }


.progress-bar.bg-danger {
  background-color: #ea0029 !important; }

.App .heJwJj,
.gKyapV {
  background-color: #ea0029; }

.App .dsKVpZ,
.App .dyHccB:hover {
  color: #ea0029; }

.App .custom-datepicker {
  background: #ea0029;
  border-radius: 50px;
  border: none;
  height: 50px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  outline: none;
  padding: 0 20px;
  text-align: center; }
  .App .custom-datepicker::-moz-placeholder, .App .custom-datepicker::-moz-placeholder {
    color: white;
    text-align: center;
    opacity: 1; }
  .App .custom-datepicker::placeholder, .App .custom-datepicker::-moz-placeholder {
    color: white;
    text-align: center;
    opacity: 1; }
  .App .custom-datepicker.white-button {
    background-color: white;
    color: #ea0029;
    text-align: center;
    border: solid;
    width: 100%;
    height: 40px;
    margin-top: 5px; }
    .App .custom-datepicker.white-button::-webkit-input-placeholder {
      color: #ea0029; }
    .App .custom-datepicker.white-button::-moz-placeholder {
      color: #ea0029; }
    .App .custom-datepicker.white-button:-ms-input-placeholder {
      color: #ea0029; }
    .App .custom-datepicker.white-button::-ms-input-placeholder {
      color: #ea0029; }
    .App .custom-datepicker.white-button::placeholder {
      color: #ea0029; }

.App .container,
.App .container-fluid {
  min-height: calc(100vh - 56px);
  margin-top: 56px;
  padding: 30px 20px 15px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.App .page-title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 22px; }
  .App .page-title.full-width {
    text-align: left;
    margin: 30px; }

.App .step-actions-container {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 350px;
  margin: 0 auto; }
  .App .step-actions-container button.button-primary {
    width: 140px;
    height: 38px;
    padding: 0;
    border-radius: 20px;
    margin-bottom: 5px; }
    .App .step-actions-container button.button-primary:active:focus {
      box-shadow: none; }
  @media (max-width: 320px) {
    .App .step-actions-container {
      width: 100%; }
      .App .step-actions-container button.button-primary {
        width: 100%; } }

@media (min-width: 576px) {
  .App .page-title.full-width {
    max-width: 550px;
    margin: 30px auto; } }

.section-container {
  display: flex;
  flex-direction: row;
  align-items: normal;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 20px 20px;
  padding: 25px; }
  .section-container .section-side {
    width: 100%;
    padding-bottom: 20px; }
  .section-container.two-column-section .left-side {
    border-right: none;
    border-bottom: 2px solid #c4c4c4; }
  .section-container.two-column-section .right-side {
    margin-top: 13px; }

@media (min-width: 768px) {
  .section-container {
    padding: 70px 90px; } }

@media (min-width: 992px) {
  .section-container {
    flex-direction: row; }
    .section-container .section-side {
      padding-bottom: 0; }
    .section-container.two-column-section .section-side {
      width: 50%;
      padding: 0; }
    .section-container.two-column-section .left-side {
      border-bottom: none;
      border-right: 2px solid #c4c4c4;
      padding-right: 6%; }
    .section-container.two-column-section .right-side {
      margin-top: 0;
      padding-left: 6%; } }

.red {
  color: #ea0029; }

.hidden {
  display: none !important; }

.App button,
.modal button {
  outline: 0;
  box-shadow: none;
  font-weight: 600; }
  .App button:hover, .App button:active, .App button:focus, .App button:active:focus,
  .modal button:hover,
  .modal button:active,
  .modal button:focus,
  .modal button:active:focus {
    box-shadow: none; }
  .App button svg,
  .modal button svg {
    margin-right: 5px; }
  .App button.button-primary,
  .modal button.button-primary {
    background-color: #ea0029;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 15px;
    width: 100%; }
    .App button.button-primary:hover,
    .modal button.button-primary:hover {
      border: none;
      background-color: #ea0029; }
    .App button.button-primary:active:not(:disabled),
    .modal button.button-primary:active:not(:disabled) {
      background-color: #ea0029;
      border: none; }
    .App button.button-primary.outlined,
    .modal button.button-primary.outlined {
      background-color: white;
      color: #ea0029;
      border: solid #ea0029; }
      .App button.button-primary.outlined:hover,
      .modal button.button-primary.outlined:hover {
        background-color: #ea0029;
        color: white;
        border: solid #ea0029; }
    .App button.button-primary.gray,
    .modal button.button-primary.gray {
      background-color: #6c757d;
      color: white;
      border: none; }
      .App button.button-primary.gray:active, .App button.button-primary.gray:hover,
      .modal button.button-primary.gray:active,
      .modal button.button-primary.gray:hover {
        background-color: #6c757d;
        color: white;
        border: none; }
    .App button.button-primary.narrow,
    .modal button.button-primary.narrow {
      padding: 10px 30px; }
    .App button.button-primary.large,
    .modal button.button-primary.large {
      padding: 20px; }
    .App button.button-primary.squared,
    .modal button.button-primary.squared {
      border-radius: 5px; }
    .App button.button-primary.round,
    .modal button.button-primary.round {
      border-radius: 15px; }
    .App button.button-primary.gradient,
    .modal button.button-primary.gradient {
      background: -webkit-linear-gradient(359.41deg, #ee315f 9.53%, #eb2237 70.25%), #ea0029;
      background: linear-gradient(90.59deg, #ee315f 9.53%, #eb2237 70.25%), #ea0029; }
    .App button.button-primary.outline,
    .modal button.button-primary.outline {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 15px;
      font-size: 18px;
      color: #000000; }
    .App button.button-primary.google,
    .modal button.button-primary.google {
      border: 3px solid #ea0029;
      height: 60px; }
    .App button.button-primary.facebook,
    .modal button.button-primary.facebook {
      border: 3px solid #2a4c95;
      height: 60px; }
    .App button.button-primary.left-icon,
    .modal button.button-primary.left-icon {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .App button.button-primary.left-icon span,
      .modal button.button-primary.left-icon span {
        margin-left: 20%; }
      .App button.button-primary.left-icon img,
      .modal button.button-primary.left-icon img {
        height: 100%; }
    .App button.button-primary.outline-gray,
    .modal button.button-primary.outline-gray {
      border: 1px solid #ea0029;
      border-radius: 0px 5px 5px 0px;
      background-color: #f1f1f1;
      color: #ea0029; }
    .App button.button-primary.outline-red,
    .modal button.button-primary.outline-red {
      background: -webkit-linear-gradient(299.28deg, #ee315f 17.96%, #ea0129 89.02%), #ea0029;
      background: linear-gradient(150.72deg, #ee315f 17.96%, #ea0129 89.02%), #ea0029;
      height: 53px;
      border: 1px solid #ea0029;
      border-radius: 0px 5px 5px 0px; }

input.form-input {
  border: none;
  border-bottom: solid 1px #c4c4c4;
  width: 100%;
  border-radius: 0;
  font-size: 12px; }

input.form-input:focus {
  border: none;
  border-bottom: solid 1px #050505;
  box-shadow: none;
  color: #050505; }

input.form-input::-webkit-input-placeholder {
  color: #cccccc; }

input.form-input::-moz-placeholder {
  color: #cccccc; }

input.form-input:-ms-input-placeholder {
  color: #cccccc; }

input.form-input::-ms-input-placeholder {
  color: #cccccc; }

input.form-input::placeholder {
  color: #cccccc; }

.input-group-prepend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap;
  border: none;
  color: #cccccc;
  padding-left: 5px; }

input.bottom-line {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #c4c4c4;
  width: 409px; }

input.bottom-line:focus {
  border-color: #f1f1f1; }

input.bottom-line::-webkit-input-placeholder {
  font-size: 14px; }

input.bottom-line::-moz-placeholder {
  font-size: 14px; }

input.bottom-line:-ms-input-placeholder {
  font-size: 14px; }

input.bottom-line::-ms-input-placeholder {
  font-size: 14px; }

input.bottom-line::placeholder {
  font-size: 14px; }

input.email-log-in {
  width: 361px;
  height: 53px;
  background: #f1f1f1;
  border-radius: 10px 0px 0px 10px; }

input.overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bold {
  font-weight: bold; }

.bolder {
  font-weight: 800 !important; }

.font-small {
  font-size: 12px !important; }

.text-capitalized {
  text-transform: capitalize; }

p.sub-heading {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 20px; }

svg.add-activity {
  width: 20px !important;
  height: 20px;
  padding: 4px;
  margin: 0 6px;
  color: #ea0029;
  border: 1px solid red;
  border-radius: 100%;
  background-color: rgba(234, 0, 41, 0.3);
  cursor: pointer; }

svg.clickable {
  cursor: pointer; }

