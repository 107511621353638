@import '../../partials/variables';
@import '../../partials/mixins';

#navbar {
	button.navbar-toggler {
		padding: 0;
		margin: 0 !important;

		span {
			font-size: 20px;
		}
	}

	.agent {
		background-color: darkslateblue;
	}

	nav {
		position: fixed;
		top: 0;
		width: 100%;
		box-shadow: 1px 0px 11px lightgray;
		background-color: white;
		z-index: 3;

		.navbar-toggler {
			border: none;
			outline: none;
		}

		.navbar-collapse {
			background-color: black;
			margin: 0.5rem -1rem -0.5rem -1rem;
		}

		li {
			width: 100%;
			cursor: pointer;

			a {
				color: white;
				margin: 18px 0;
			}

			a:hover {
				color: $primary-red;
				font-weight: bold;
				text-decoration: none;
			}
		}

		.nav-footer {
			@include flexRow(center, space-evenly);
			height: 73px;
			padding: 17px;
			background-color: #1b1b1b;

			a {
				font-weight: bold;
				color: $primary-red;
			}

			.nav-item {
				max-width: 50%;

				&:nth-child(2) {
					border-left: solid 1px #2c2c2c;
				}
			}
		}
	}

	.go-back-icon {
		svg {
			font-size: 26px;
			cursor: pointer;
		}
	}

	@media (min-width: $small-device) {
		button.navbar-toggler {
			// display: none;
		}
	}
}
