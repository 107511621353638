@import './variables';

.bold {
	font-weight: bold;
}

.bolder {
	font-weight: 800 !important;
}

.font-small {
	font-size: 12px !important;
}

.text-capitalized {
	text-transform: capitalize;
}

p.sub-heading {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
	margin-top: 20px;
}

@media (min-width: $medium-device) {
}

@media (min-width: $large-device) {
}
