@import '../../partials/variables';

#group-controls {
	display: flex;
	justify-content: center;

	.group-controles-user-profile {
		padding: 15px;
		top: -232px;
		position: absolute;
		width: 240px;
		height: 257px;
		display: flex;
		background: $secondary-gray;
		box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
		border-radius: 15px;
	}
}
