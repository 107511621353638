@import '../../partials/variables';
@import '../../partials/mixins';

#new-trip-container {
	form {
		@include flexColumn(center, center);
	}

	.step-container {
		@include flexRow(normal, center, wrap);
		// justify-content: space-between;
		width: 100%;
		max-width: 750px;
		min-height: 300px;
		background: white;
		box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
		border-radius: 15px;
		padding: 30px 45px;
		margin-bottom: 30px;

		.step-title-container {
			@include flexRow(center, center);
			width: 100%;
			margin-bottom: 15px;

			.step-title {
				text-align: initial;
				padding: 0;

				h2 {
					font-weight: bold;
					font-size: 18px;
					margin: 0;
					line-height: 22px;
				}
			}

			.step-number {
				@include flexRow(center, center);
				span {
					@include text(bold, $primary-red, center, 18px);
					@include border(solid, 3px, $primary-red, 100%);
					margin-right: 15px;
					width: 30px;
					height: 30px;
				}
			}
		}

		.options-container {
			@include flexRow(center, space-evenly, wrap);
			width: 100%;
			padding-left: 0;
			border-left: none;

			> [class*='-container'] {
				@include flexRow(center, center, wrap);
				width: 100%;
			}

			[class*='-box'] {
				@include flexRow(center, center, wrap);
				width: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				border: none;
				border-radius: 10px;
				cursor: pointer;

				&.active {
					border: 2px solid $primary-red;
				}

				&.inactive {
					-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
				}

				span {
					margin: auto;
					font-weight: bold;
					text-align: center;
					color: white;
				}
			}
		}
	}

	.progress-bar-container {
		@include flexRow(normal, center);
		width: 100%;
		margin-bottom: 30px;
		border-radius: 50px;

		.progress {
			position: relative;
			background-color: black;
			width: 340px;
			height: 35px;
			border-radius: 50px;

			.progress-bar {
				border-radius: inherit;
			}
		}

		.progress-status {
			@include flexRow(normal, flex-end);
			@include absolute(auto, 17px, auto, auto);
			color: white;
			font-size: 1rem;
		}
	}

	@media (max-width: $extra-small-device) {
		.step-container {
			padding: 20px;
		}
	}

	@media (min-width: $small-device) {
		.step-container {
			padding: 30px 115px;

			[class*='-box'] {
				span {
					font-size: 18px;
				}
			}
		}
	}

	@media (min-width: $large-device) {
		.step-container {
			padding: 40px 60px;

			.step-title-container {
				@include flexColumn(flex-start, center);
				width: 40%;

				.step-title {
					padding-right: 20px;

					h2 {
						font-size: 24px;
						line-height: 29px;
					}
				}

				.step-number {
					margin-bottom: 10px;
					span {
						width: 40px;
						height: 40px;
						font-size: 24px;
					}
				}
			}

			.options-container {
				// @include flexRow(inherit, space-between, inherit);
				justify-content: space-between;
				border-left: solid;
				padding-left: 50px;
				width: 60%;
			}
		}
	}
}
