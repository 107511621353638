@import '../../partials/variables';
@import '../../partials/mixins';

.user-photo {
	@include flexColumn(center);

	.user-image {
		position: relative;
		border: none;
		background-size: cover;
	}

	.redknot-verified-logo {
		@include absolute(auto, 0, 15px, auto);
		width: 30px;
		height: 30px;
		background-size: cover;
		background-position: top;

		@media (max-width: $large-device) {
			display: none;
		}
	}

	.user-name {
		font-size: 11px;
		margin-top: 6px;
	}
}
