@import '../../partials/variables';
@import '../../partials/mixins';

.card-badge {
	@include flexRow(center);
	@include absolute(-30px, auto, auto, 0);
	color: $star-yellow;
	background-color: black;
	padding: 5px 15px;
	padding-bottom: 50px;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	z-index: -1;

	svg {
		font-size: 20px;
		margin-right: 5px;
	}

	div {
		font-size: 9px;
		font-weight: bold;
	}
}
