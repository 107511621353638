@import './variables';
@import './mixins';

.App,
.modal {
	button {
		outline: 0;
		box-shadow: none;
		font-weight: 600;
		// width: 100%;

		&:hover,
		&:active,
		&:focus,
		&:active:focus {
			box-shadow: none;
		}

		svg {
			margin-right: 5px;
		}

		&.button-primary {
			@include button();

			&:hover {
				border: none;
				background-color: $primary-red;
			}

			&:active:not(:disabled) {
				background-color: $primary-red;
				border: none;
			}

			&.outlined {
				@include color(white, $primary-red, solid $primary-red);

				&:hover {
					@include color($primary-red, white, solid $primary-red);
				}
			}

			&.gray {
				@include color($inactive-gray, white, none);

				&:active,
				&:hover {
					@include color($inactive-gray, white, none);
				}
			}

			&.narrow {
				padding: 10px 30px;
			}

			&.large {
				padding: 20px;
			}

			&.squared {
				border-radius: 5px;
			}

			&.round {
				border-radius: 15px;
			}

			&.gradient {
				background: linear-gradient(
						90.59deg,
						#ee315f 9.53%,
						#eb2237 70.25%
					),
					$primary-red;
			}
			&.outline {
				background: #ffffff;
				box-sizing: border-box;
				border-radius: 15px;
				font-size: 18px;
				color: #000000;
			}
			&.google {
				border: 3px solid $primary-red;
				height: 60px;
			}

			&.facebook {
				border: 3px solid #2a4c95;
				height: 60px;
			}

			&.left-icon {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				span {
					margin-left: 20%;
				}

				img {
					height: 100%;
				}
			}

			&.outline-gray {
				border: 1px solid $primary-red;
				border-radius: 0px 5px 5px 0px;
				background-color: $secondary-gray;
				color: $primary-red;
			}
			&.outline-red {
				background: linear-gradient(
						150.72deg,
						#ee315f 17.96%,
						#ea0129 89.02%
					),
					$primary-red;
				height: 53px;
				border: 1px solid $primary-red;
				border-radius: 0px 5px 5px 0px;
			}
		}
	}
}
