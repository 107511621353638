@import '../../partials/variables';
@import '../../partials/mixins';

#profile-container {
	margin-top: 35px;

	.fields-container {
		@include flexColumn();
		margin-top: 15px;

		.main-fields p {
			text-align: center;
			margin: 0;
		}

		.city-field {
			color: $primary-red;
		}

		.country-field {
			font-size: 12px;
		}

		.name-field {
			color: black;
			font-weight: 900;
			font-size: 22px;
			margin-top: 10px;
		}

		.bio-field {
			margin-top: 16px;
			text-align: justify;
			text-align-last: center;
		}
	}

	.actions-container {
		margin-top: 1rem;
	}

	button {
		margin-bottom: 15px;
	}

	.tariff {
		text-align: right;
		padding: 25px 0;

		.amount {
			font-weight: 900;
			font-size: 18px;
		}
	}
}
