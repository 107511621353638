@import '../../partials/mixins';

.example-day-entry {
	@include flexRow();
	margin-bottom: 10px;

	input[type='time'] {
		margin-right: 10px;
		max-width: 115px;
		cursor: text;
	}
}
