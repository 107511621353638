@import '../../partials/variables';
@import '../../partials/mixins';

#new-trip-container {
	#step-5 {
		#loader-container {
			height: 100%;
		}

		&.step-container {
			min-width: unset;

			.step-title-container {
				width: 100%;
				@include flexRow(center, center);
			}

			@media (min-width: $extra-small-device) {
				padding: 30px 20px;
			}

			@media (min-width: $small-device) {
				padding: 30px 45px;
			}

			@media (min-width: $large-device) {
				min-width: 888px;

				.step-number {
					margin-bottom: 0;
				}
			}
		}
	}
}
